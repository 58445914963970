<style scoped>
    .payment-wrapper {
        min-height: 300px;
        justify-content: center;
        align-items: center;
    }
    .form-wrapper {
        height: 100%;
        width: 100%;
    }
</style>

<template>
    <div class="row payment-wrapper">
        <div v-show="load">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
        <div v-show="!load" class="form-wrapper">
            <div v-if="errorText">{{ errorText }}</div>
            <component v-else :is="currentComponent" :renderForm="renderForm" :renderError="renderError"/>
        </div>
    </div>
</template>

<script>
    import SubscriptionPlatron from '@/components/SubscriptionPlatron.vue';
    import SubscriptionImpaya from '@/components/SubscriptionImpaya.vue';

    export default {
        name: 'Subscription',

        components: {
            platron: SubscriptionPlatron,
            impaya: SubscriptionImpaya
        },

        data: () => ({
            load: true,
            errorText: null,
            currentComponent: null
        }),

        methods: {
            renderForm() {
                //@TODO сделать перемотку к началу контейнера, а не страницы
                window.scrollTo(0,0);

                this.load = false;
            },

            renderError(error) {
                this.load = false;
                this.errorText = 'ERROR!';
                console.warn(error);
            }
        },

        mounted () {
            if (window.bid.paymentSystem === null) {
                this.renderError('Для сайта установлена платная анкета, но не определена платежная система!');
            } else {
                this.currentComponent = window.bid.paymentSystem;
            }
        }
    }
</script>
