
import { createApp } from 'vue'
import App from './App.vue'
import * as DataParams from './assets/js/data_params.js'
import Mask from '@/directives/Mask'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import PrimeVue from 'primevue/config';
import { createStore } from 'vuex'

var bootstrapLoad = DataParams.get('loadBootstrap', 'true') === 'true';

//Подключение бутстрапа
if (bootstrapLoad) {
    var bootstrapStyle = document.createElement('link');
    bootstrapStyle.rel = 'stylesheet';
    bootstrapStyle.href = DataParams.getOriginUrl() + '/css/bootstrap.css';
    document.getElementsByTagName('head')[0].appendChild(bootstrapStyle);
}
var themeColor = DataParams.get('theme', 'md-blue');

//Подключение бутстрапа
    var themeStyle = document.createElement('link');
    themeStyle.rel = 'stylesheet';
    themeStyle.href = DataParams.getOriginUrl() + `/css/themes/${themeColor}.css`;
    document.getElementsByTagName('head')[0].appendChild(themeStyle);


window.addEventListener('load', () => {
    var $consent = document.querySelector('#anketa-app consent');
    if ($consent) {
        window.consent = $consent.innerHTML;
    }

    const app = createApp(App).use(Quasar, quasarUserOptions,);

    const store = createStore({
        state () {
            return {
                anketa: {
                    amount: 0,
                    period: 0
                }
            }
        },
        mutations: {
            updateAnketa (state, payload) {
                state.anketa = Object.assign({}, state.anketa, payload)
            }
        }
    })
  
    app.directive('mask', Mask);
    app.use(PrimeVue, { ripple: true, inputStyle: 'filled' });
    app.use(store);

    app.mount('#anketa-app');

    // const authParams = {
    //     redirectUri: "https://mysite.ru/auth/success",
    //     responseType: "code",
    //     clientId: "XXXX",
    //     state: "XXXX",
    //   };
  
    //   const uiParams = {
    //     container: "#container-for-tid-button",
    //     size: "m",
    //     color: "primary",
    //     text: "Войти с",
    //     target: "_self",
    //   };
  
    //   const tidSdk = new TidSDK(authParams);
  
    //   tidSdk.addButton(uiParams);
});
