var token;
var key;

var entry = function(tel, email, consent, amount, period) {
    var payload = {
        phone: tel,
        bidId: window.bid ? window.bid.id : null,
        email: email,
        host: window.location.hostname,
        consent: consent,
        amount: amount,
        period: period
    }
    return fetch(process.env.VUE_APP_API_BASE_URL + '/api/bid/validate-phone', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(payload)
    }).then(response => {
        return response.json();
    }).then(response => {
        token = response.token
        key = response.key
        window.bid = {
            id: response.bidId
        }
        return response;
    });
};

var confirm = function(code) {
    var payload = {
        token: token,
        key: key,
        code: code
    }
    return fetch(process.env.VUE_APP_API_BASE_URL + '/api/bid/validate-phone/confirm', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(payload)
    }).then(response => {
        return response.json();
    }).then(response => {
        return response;
    });
};
var confirm_cab = function() {
    var payload = {
        token: token,
        key: key,

    }
    return fetch(process.env.VUE_APP_API_BASE_URL + '/api/client/cabinet-url', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(payload)
    }).then(response => {
        return response.json();
    }).then(response => {
        return response;
    });
};

export { entry, confirm, confirm_cab }