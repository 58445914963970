import axios from 'axios';
import * as GetParams from "@/assets/js/get_params.js";

var key;
var token;

var client = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
});

var entry = function (phone, host) {
	return client.post('/client/authorization/entry', {
        phone: phone,
        host: host
	}).then(response => {
		key = response.data.key;
		token = response.data.token;
		return response.data;
    });
};

var confirm = function (code) {
	return client.post('/client/authorization/confirm', {
		key: key,
		token: token,
		code: code
	}).then(response => {
		if (response.data.bearer) {
			localStorage.setItem('token', response.data.bearer);
		}
		return response.data;
    });
};

var logout = function () {
	var config = {headers: {'Authorization': 'Bearer ' + getToken()}};
	localStorage.removeItem('token');
	return client.post('/client/authorization/logout', {}, config);
};

var getToken = function() {
	return localStorage.getItem('token');
};

var setTokenFromQuery = function() {
	let url = new URL(location.href);
	const params = new URLSearchParams(url.search);
	let token = params.get("bearer");
	window.bid = {
		id: params.get("bidId")
	}

	if (token) {
		localStorage.setItem('token', token);
	}
};

var load = function(endpoint, data) {
	if (typeof data === 'undefined') {
		data = {};
	}
	var config = {headers: {'Authorization': 'Bearer ' + getToken()}};
	return client.post(endpoint, data, config).then(response => response.data);
};

var oauth2 = function(client) {
	const host = location.hostname.replace("lk.", "")
	const redirect_url = `https://lk.${host}/profile`
	const amount = GetParams.get('amount')
	const period = GetParams.get('period')

	window.location=process.env.VUE_APP_API_BASE_URL + `/oauth2/client/${client}/connect?host=${host}&redirect_url=${redirect_url}&host_type=anketa&amount=${amount}&period=${period}&consent=true`;
};

export { entry, confirm, logout, getToken, load, setTokenFromQuery, oauth2 }
