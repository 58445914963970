//id яндекс метрики
window.addEventListener('load', function() {
    if (!!window.ymId) {
        return;
    }

    window.ymId = !window.Ya ? null : window.Ya._metrika.getCounters()[0].id;
});

/**
 * Цель на заполнение анкеты
 */
var newBid = function () {
    if (window.ymId) {
        ym(window.ymId, 'reachGoal', 'new_bid');
    }
    if (typeof(window.gtag) !== 'undefined') {
        gtag('event','click',{'event_category':'general','event_label':'lead'});
    }
};

export { newBid }
