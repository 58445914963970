/*
 * Модуль для получения значений из url параметров
 */

const getParams = (new URL(document.location)).searchParams;

/*
 * Получение get параметра из адресной строки
 */
var get = function(key) {
    return getParams.get(key);
};

/*
 * Получение click_id.
 * Парметр разный для разных источников.
 */
var getClickId = function() {
    switch(get('utm_source')) {
        case 'guruleads':
            return get('clickid');
        case 'saleads':
            return get('click');
        case 'c2m':
            return get('click_id');
        case 'leadstech':
            return get('clickid');
        case 'leadgid':
            return get('utm_campaign');
        case 'devtek':
            return get('clickid');
        case 'beegl':
            return get('clickid');
        case 'epiczaim':
            return get('click_id');
        case 'leadcraft':
            return get('click_id');
        default:
            return get('click_id');
    }
};

/*
 * Получение webmaster_id.
 * Парметр разный для разных источников.
 */
var getWmId = function() {
    switch(get('utm_source')) {
        case 'guruleads':
            return get('wm_id');
        case 'saleads':
            return get('wm');
        case 'c2m':
            return get('user_id');
        case 'leadstech':
            return get('wmid');
        case 'leadgid':
            return get('utm_medium');
        case 'devtek':
            return get('devtek_id');
        case 'beegl':
            return get('wm_id');
        case 'epiczaim':
            return get('wm_id');
        case 'leadcraft':
            return get('web_id');
        default:
            return get('wm_id');
    }
};

export { get, getClickId, getWmId }
