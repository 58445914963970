<style scoped>
    .radios {
        display: flex;
        align-items: center;
        height: calc(1.5em + 0.75rem + 2px);
    }

    .button-wrapper {
        justify-content: flex-end;
    }

    @media (max-width: 767px) {
        .button-wrapper {
            justify-content: center;
        }
    }
</style>

<template>
    <div class="row">
        <form class="col-md-12" novalidate @submit="submit">


            <h3 class="text-left bord-bot pb-2 pt-3"><span>Личные данные</span> <span>Шаг 2 из 2</span> </h3>
            <div class="row">
                <div class="col-md-6 pb-2">
                    <label for="anketa-lastname">Фамилия</label>
                    <input type="text" class="form-control" id="anketa-lastname" :class="{'is-invalid': !valid.lastname}" v-model="form.lastname" @change="validate" autocomplete="off" v-on:keypress="isLetter($event)">
                </div>
                <div class="col-md-6 pb-2">
                    <label for="anketa-firstname">Имя</label>
                    <input type="text" class="form-control" id="anketa-firstname" :class="{'is-invalid': !valid.firstname}" v-model="form.firstname" @change="validate" autocomplete="off" v-on:keypress="isLetter($event)">
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 pb-2">
                    <label for="anketa-secondname">Отчество</label>
                    <input type="text" class="form-control" id="anketa-secondname" :class="{'is-invalid': !valid.secondname}" v-model="form.secondname" @change="validate" autocomplete="off" v-on:keypress="isLetter($event)">
                </div>
                <div class="col-md-6 pb-2">
                    <label for="anketa-dob">Дата рождения</label>
                    <input type="text" class="form-control" id="anketa-dob" v-mask="{mask: '99.99.9999'}" :class="{'is-invalid': !valid.dob}" v-model="form.dob" @change="validate" autocomplete="off" placeholder="ДД.ММ.ГГГГ">
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 pb-2">
                    <label for="anketa-birthPlace">Место рождения</label>
                    <input type="text" class="form-control" id="anketa-birthPlace" :class="{'is-invalid': !valid.birthPlace}" v-model="form.birthPlace" @change="validate" autocomplete="off">
                </div>
                <div class="col-md-6 pb-2">
                    <label>Пол</label>
                    <div class="radios">
                        <div class="custom-control custom-radio mr-4">
                            <input type="radio" id="anketa-male" class="custom-control-input" :class="{'is-invalid': !valid.sex}" value="male" v-model="form.sex" @change="validate">
                            <label class="custom-control-label" for="anketa-male">М</label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input type="radio" id="anketa-female" class="custom-control-input" :class="{'is-invalid': !valid.sex}" value="female" v-model="form.sex" @change="validate">
                            <label class="custom-control-label" for="anketa-female">Ж</label>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row">
                <div class="col-md-6 pb-2">
                   <label>Регион</label>
                    <v-select
                        class="form-control"
                        :class="{'is-invalid': !valid.region}"
                        v-model="form.region"
                        :filterable="false"
                        :options="Object.keys(regionSuggestOptions)" 
                        @search="fetchRegionSuggest"
                        id="anketa-region"
                    >
                        <template v-slot:no-options="{ search, searching }">Регион не найден</template>
                    </v-select>
                </div>
                <div class="col-md-6 pb-2">
                    <label>Город</label>
                    <v-select
                        class="form-control"
                        :class="{'is-invalid': !valid.city}"
                        v-model="form.city"
                        :filterable="false"
                        :options="Object.keys(citySuggestOptions)" 
                        @search="fetchCitySuggest"
                        id="anketa-location"
                    >
                        <template v-slot:no-options="{ search, searching }">Город не найден</template>
                    </v-select>
                </div>
            </div>
            
                <h4 class="text-left pt-3 pb-2">Паспортные данные</h4>
                <div class="row">
                    <div class="col-md-6 pb-2">
                        <label for="anketa-series">Серия</label>
                        <input type="text"
                               class="form-control"
                               id="anketa-series"
                               v-mask="{mask: '9999'}"
                               :class="{'is-invalid': !valid.series}"
                               v-model="form.series"
                               @change="validate"
                               autocomplete="off">
                    </div>
                    <div class="col-md-6 pb-2">
                        <label for="anketa-number">Номер</label>
                        <input type="text"
                               class="form-control"
                               id="anketa-number"
                               v-mask="{mask: '999999'}"
                               :class="{'is-invalid': !valid.number}"
                               v-model="form.number"
                               @change="validate"
                               autocomplete="off">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 pb-2">
                        <label for="anketa-fmsCode">Код</label>
                        <input type="text"
                               class="form-control"
                               id="anketa-fmsCode"
                               v-mask="{mask: '999-999'}"
                               :class="{'is-invalid': !valid.fmsCode}"
                               @focusout="getFmsUnit"
                               v-model="form.fmsCode"
                               @change="validate"
                               autocomplete="off">
                    </div>
                    <div class="col-md-6 pb-2">
                        <label for="anketa-issueDate">Дата выдачи</label>
                        <input type="text"
                               class="form-control"
                               v-mask="{mask: '99.99.9999'}"
                               :class="{'is-invalid': !valid.issueDate}"
                               id="anketa-issueDate"
                               v-model="form.issueDate"
                               @change="validate"
                               autocomplete="off">
                    </div>
                </div>
            
            <div class="row pt-3 pb-4">
                <div class="col-md-12 d-flex button-wrapper">
                    <button class="btn btn-primary btn-lg btn-block" style="width: 200px;" @click="next">Продолжить</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { debounce } from 'debounce';
import vSelect from '@/../node_modules/vue-select/src/index.js';
import * as Bid from '@/assets/js/bid/default_create.js';
import * as Suggest from '@/assets/js/suggest.js';
import * as Validate from '@/assets/js/validators.js';
import * as GetParams from '@/assets/js/get_params.js';
import * as DataParams from '@/assets/js/data_params.js';
import * as Goal from '@/assets/js/goal.js';

export default {
    name: 'FormDefaultStepTwo',
    components: { vSelect },

    props: {
        formType: String,
        forwardPage: Function,
        backwardPage: Function,
        previousPagePromise: Promise,
    },

    data: () => ({
        consentTextDefault: 'Даю свое согласие на <a href="/docs/Soglasie_na_obrabotku_personalnyh_dannyh.pdf" target="_blank">обработку персональных данных</a>, на <a href="/docs/Soglasie_na_poluchenie_reklamnyh_materialov.pdf" target="_blank">получение</a> рекламных материалов. Соглашаюсь с условиями <a href="/docs/Oferta.pdf" target="_blank">оферты</a>. Я принял соглашение на оформление <a href="/docs/Prilozhenie_4_Soglashenie_na_oformlenie_platnoj_podpiski.pdf" target="_blank">платной подписки</a>. Для активации сервиса будет списан 1 рубль, дальнейшее использование сервиса будет проходит в соответствии с <a href="/docs/Prilozhenie_2_Informacija_o_dejstvujushhih_tarifah.pdf" target="_blank">тарифами сервиса</a>. Стоимость услуги четыреста девяносто восемь рублей каждые пять дней по подписке. Подписка не гарантирует получение займа!',
        isNextClicked: false,
        isPartialSubmitted: false,
        sendPromise: Promise.resolve(),
        regionSuggestOptions: [],
        citySuggestOptions: [],
        form: {
            lastname: null,
            firstname: null,
            secondname: null,
            dob: null,
            birthPlace: null,
            region: null,
            city: null,
            cityKladr: null,
            regionKladr: null,
            sex: null,
            series: null,
            number: null,
            fmsCode: null,
            fmsUnit: null,
            issueDate: null,
            consent: false,
        },
        valid: {
            lastname: true,
            firstname: true,
            secondname: true,
            dob: true,
            birthPlace: true,
            region: true,
            city: true,
            sex: true,
            series: true,
            number: true,
            fmsCode: true,
            fmsUnit: true,
            issueDate: true,
            consent: true,
        },
        
    }),

    methods: {
        getConsentText() {
            return window.consent || this.consentTextDefault;
        },
        

        //проверка имени и тд
        isLetter(e) {
            let char = String.fromCharCode(e.keyCode);
            if(/^[А-Яа-я]+$/.test(char)) return true;
            else e.preventDefault();
        },
        //Нажатие на кнопку "продолжить"
        next(e) {
            this.isNextClicked = true;
            if(!this.validate()) {
                e.preventDefault();
            }
        },

        //Валидация формы
        validate() {
            //Не валидируем, пока не была нажата кнопка далее
            if (this.isNextClicked === false) {
                return true;
            }
            this.valid.lastname = Validate.noempty(this.form.lastname);
            this.valid.firstname = Validate.noempty(this.form.firstname);
            this.valid.secondname = Validate.noempty(this.form.secondname);
            this.valid.dob = Validate.date(this.form.dob);
            this.valid.birthPlace = Validate.noempty(this.form.birthPlace);
            this.valid.region = Validate.noempty(this.form.region);
            this.valid.city = Validate.noempty(this.form.city);
            this.valid.sex = Validate.noempty(this.form.sex);
            this.valid.series = Validate.series(this.form.series);
            this.valid.number = Validate.number(this.form.number);
            this.valid.fmsCode = Validate.fmsCode(this.form.fmsCode);
            this.valid.issueDate = Validate.date(this.form.issueDate);

            for (var field in this.valid) {
                if (this.valid[field] === false) {
                    return false;
                }
            }

            return true;
        },

        //Отправка данных на сервер, когда были введены валидные телефон и email
        sendPartial() {
            //Если данные уже отправлены
            if (this.isPartialSubmitted === true) {
                return true;
            }

            if (!Validate.phone(this.form.phone) || !Validate.email(this.form.email)) {
                return;
            }

            this.isPartialSubmitted = true;
            this.sendPromise = Bid.send(this.form);
        },

        //Отправка формы
        submit(e) {
            e.preventDefault();
            Goal.newBid();
            this.sendPromise = this.sendPromise.then(() => Bid.send(this.form));
            this.forwardPage(this.sendPromise);
        },

        //Получения названия подразделения по коду
        getFmsUnit() {
            Suggest.suggestFmsCode(this.form.fmsCode).then(data => {
                this.form.fmsUnit = data;
            });
        },
        //Region suggestions
        fetchRegionSuggest: debounce(function(search, loading) {
            if(search.length < 2) {
                this.form.regionKladr = this.regionSuggestOptions[this.form.region];
                this.citySuggestOptions = {};
                this.form.city = null;
                this.form.cityKladr = null;
                return;
            }
            loading(true);
            Suggest.suggestRegion(search).then(data => {
                this.regionSuggestOptions = {};
                data.forEach(el => this.regionSuggestOptions[el.text] = el.id);
                loading(false);
            });
        }, 500),

        //City suggestions
        fetchCitySuggest: debounce(function(search, loading) {
            if(search.length < 2) {
                this.form.cityKladr = this.citySuggestOptions[this.form.city];
                return;
            }
            loading(true);
            Suggest.suggestCity(search, this.form.regionKladr).then(data => {
                this.citySuggestOptions = {};
                data.forEach(el => this.citySuggestOptions[el.text] = el.id);
                loading(false);
            });
        }, 500),
    },

    mounted () {
        //Подстановка geo по ip
        Suggest.getGeoFromIp().then(geo => {
            if (geo.cityKladr === null) {
                return;
            }
            this.form.region = geo.region;
            this.form.regionKladr = geo.regionKladr;
            this.form.city = geo.city;
            this.form.cityKladr = geo.cityKladr;
            this.regionSuggestOptions[this.form.region] = this.form.regionKladr;
            this.citySuggestOptions[this.form.city] = this.form.cityKladr;
        });
    }
}
</script>
