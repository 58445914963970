<style scoped>
    .wrapper {
        min-height: 300px;
        justify-content: center;
        align-items: center;
    }
    
</style>

<template>
    <div class="row ">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
</template>

<script>

export default {
    name: 'Redirect',

    props: {
        formType: String,
        forwardPage: Function,
        backwardPage: Function,
        previousPagePromise: Promise,
    },

    mounted () {
        this.previousPagePromise.then(() => {
            window.location.replace(window.bid.showcaseUrl);
        });
    }
}
</script>
