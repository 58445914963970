
<style lang="scss">
// .p-fieldset .p-fieldset-content{
//   padding: 0 !important;
//   padding-bottom: 1rem !important;
//   padding-top: 1rem !important;
// }

html{
  font-size: 100% !important;
}

.p-fieldset-legend-text{
  font-weight: bold;
  font-size: 20px;
}





legend{
  font-size: 1rem !important;
}
label{
  margin-bottom: 0 !important;
}
.p-fieldset:has(.p-invalid) .p-fieldset-legend{
  box-shadow: inset 0 0 0 1px #b00020, inset 0 0 0 1px #b00020, inset 0 0 0 1px #b00020, inset 0 0 0 1px #b00020;

}

.p-fieldset-legend > a, .p-fieldset-legend > span{
  justify-content: flex-start;
}

.p-fieldset-legend{
  width: 100%;
}
.p-fieldset-toggler{
  position: absolute;
  right: 2%;
}
.p-accordion a {
  text-decoration: none !important;
}
.f-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
}
.f-margin-top {
  margin-top: 20px;
}
.f-direction-column {
  display: flex;
  flex-direction: column;
}
// .p-float-label {
//   display: flex !important;
//   label {
//     color: black !important;
//     top: 34% !important;
//     font-size: 14px !important;
//   }
// }
.p-inputtext {
  height: 48px;
  font-size: 15px !important;
}
small {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 12px !important;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 9px !important;
  height: 9px !important;
}
.p-accordion {
  label {
    margin-bottom: 0 !important;
  }
}
#female {
  margin-left: 20px;
}
.f-width-32 {
  width: 32%;
}
.field-checkbox{
  margin-top: 20px;
  margin-bottom: 20px;
}
.p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check:before {
  top: 9px !important;
  left: 2px !important;
}
.p-autocomplete.p-invalid.p-component > .p-inputtext,
.p-inputtext.p-invalid.p-component {
  box-shadow: inset 0 0 0 1px #b00020, inset 0 0 0 1px #b00020,
    inset 0 0 0 1px #b00020, inset 0 0 0 1px #b00020;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link{
    background: #f6f6f6;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.87);
}
.label-wrapper{
  display: flex;
  flex-direction: column;
}
.title{
  text-align: center;
  margin-bottom: 20px;
}
//media
@media (max-width:530px) {
  .label-wrapper{
    margin-bottom: 20px;
  }
  .f-wrapper{
    justify-content: center;
    align-items: center;
  }
 
}
</style>

<template>


  <form ref="myForm" class="p-fluid">

    <!-- <Accordion lazy :active-index="activeIndex"> -->


      <Fieldset :toggleable="false" :collapsed="false" legend="Параметры займа">
      
      <div class="f-wrapper f-margin-top">
        <div class="label-wrapper">
        <div class="f-amount f-direction-column p-float-label">
          <InputText id="amount" type="number"
          :class="{ 'p-invalid': v$.form.amount.$invalid }"
          v-model="v$.form.amount.$model"
          @blur="v$.form.amount.$touch"
          />
          <label for="amount">Сумма займа (рубли)*</label>
          
        </div>
        <small
            v-if="
              v$.form.amount.$invalid ||
              v$.form.amount.$pending.$response
            "
            class="p-error"
            >{{
              v$.form.amount.required.$message
            }}</small
          >
          <small v-else id="">30000</small>
        </div>
        <div class="label-wrapper">
        <div class="f-period f-direction-column p-float-label">
          <InputText id="period" type="number"
          :class="{ 'p-invalid': v$.form.period.$invalid }"
          v-model="v$.form.period.$model"
          @blur="v$.form.period.$touch"
          />
          <label for="period">Срок займа (дни)*</label>
          
        </div>
        <small
            v-if="
              v$.form.period.$invalid ||
              v$.form.period.$pending.$response
            "
            class="p-error"
            >{{
              v$.form.period.required.$message
            }}</small
          >
          <small v-else id="">60</small>
        </div>
      </div>
    </Fieldset>


      <Fieldset :toggleable="true" legend="Контактная информация">
      
        <div class="f-wrapper">
          <div class="label-wrapper">
            <div class="f-phone f-direction-column p-float-label">
            <InputMask
              id="phone"
              mask="+7(999)9999999"
              :class="{ 'p-invalid': v$.form.phone.$invalid }"
              v-model="v$.form.phone.$model"
              @blur="v$.form.phone.$touch"
            />
            <label :class="{ 'p-error': v$.form.phone.$invalid }" for="phone"
              >Телефон*</label
            >

          </div>
            <small
              v-if="v$.form.phone.$invalid || v$.form.phone.$pending.$response"
              class="p-error">
            {{
                v$.form.phone.required.$message
            }}</small
            >
            <small v-else id="">+79991234567</small>
          </div>
          <div class="label-wrapper">
          <div class="f-email f-direction-column p-float-label">
            

            
            <AutoComplete
              id="email"
              :suggestions="emailSuggestOptions"
              @complete="fetchEmailSuggest($event.query)"
              :class="{ 'p-invalid': v$.form.email.$invalid }"
              v-model="v$.form.email.$model"
              @blur="v$.form.email.$touch"
            />
            <label :class="{ 'p-error': v$.form.email.$invalid }" for="email"
              >Email*</label
            >
            
          </div>
          <small
              v-if="v$.form.email.$invalid || v$.form.email.$pending.$response"
              class="p-error"
              >{{
                v$.form.email.required.$message
              }}</small
            >
            <small v-else id="">example@mail.ru</small>
          </div>
        </div>
      </Fieldset>
      <Fieldset :toggleable="true" legend="Личные данные">
      
        <div class="f-wrapper">
          <div class="label-wrapper">
          <div class="f-lastname f-direction-column p-float-label">
            <AutoComplete
              id="lastname"
              :suggestions="lastnameSuggestOptions"
              @complete="fetchLastname($event.query)"
              :class="{ 'p-invalid': v$.form.lastname.$invalid }"
              v-model="v$.form.lastname.$model"
              @blur="v$.form.lastname.$touch"/>
            <label
              :class="{ 'p-error': v$.form.lastname.$invalid }"
              for="lastname">Фамилия*</label>
            
          </div>
          <small v-if="v$.form.lastname.$invalid || v$.form.lastname.$pending.$response" class="p-error"
              >{{
                v$.form.lastname.required.$message
              }}</small>
            <small v-else id="">Иванов</small>
          </div>
          <div class="label-wrapper">
          <div class="f-name f-direction-column p-float-label">
            <AutoComplete
              id="firstname"
              :suggestions="firstnameSuggestOptions"
              @complete="fetchFirstname($event.query)"
              :class="{ 'p-invalid': v$.form.firstname.$invalid }"
              v-model="v$.form.firstname.$model"
              @blur="v$.form.firstname.$touch"
            />
            <label for="firstname">Имя*</label>
            
          </div>
          <small
              v-if="
                v$.form.firstname.$invalid ||
                v$.form.firstname.$pending.$response
              "
              class="p-error"
              >{{
                v$.form.firstname.required.$message
              }}</small
            >
            <small v-else id="">Иван</small>
        </div>
        </div>
        <div class="f-wrapper f-margin-top">
          <div class="label-wrapper">
          <div class="f-secondname f-direction-column p-float-label">
            <AutoComplete
              id="secondname"
              :suggestions="secondnameSuggestOptions"
              @complete="fetchSecondname($event.query)"
              :class="{ 'p-invalid': v$.form.secondname.$invalid }"
              v-model="v$.form.secondname.$model"
              @blur="v$.form.secondname.$touch"
            />
            <label for="secondname">Отчество*</label>
            
          </div>
          <small
              v-if="
                v$.form.secondname.$invalid ||
                v$.form.secondname.$pending.$response
              "
              class="p-error"
              >{{
                v$.form.secondname.required.$message
              }}</small
            >
            <small v-else id="">Иванович</small>
        </div>
        <div class="label-wrapper">
          <div class="f-dob f-direction-column p-float-label">
            <InputMask
              id="dob"
              mask="99.99.9999"
              :class="{ 'p-invalid': v$.form.dob.$invalid }"
              v-model="v$.form.dob.$model"
              @blur="v$.form.dob.$touch"
            />
            <label for="dob">Дата рождения*</label>
            
          </div>
          <small v-if="v$.form.dob.required.$invalid" class="p-error">{{
              v$.form.dob.required.$message
            }}</small>
            <small
              v-else-if="v$.form.dob.dobValidate.$invalid"
              class="p-error"
              >{{ v$.form.dob.dobValidate.$message }}</small
            >
            <small v-else id="">15.01.1990</small>
        </div>
        </div>
        <div class="f-wrapper f-direction-column f-margin-top">
          <div>Пол*</div>
          <div class="radios field-radiobutton">
            <RadioButton
              id="male"
              value="М"
              :class="{ 'p-invalid': v$.form.sex.$invalid }"
              v-model="v$.form.sex.$model"
              @change="v$.form.sex.$touch"
            />
            <label for="male">М</label>
            <RadioButton
              id="female"
              value="Ж"
              :class="{ 'p-invalid': v$.form.sex.$invalid }"
              v-model="v$.form.sex.$model"
              @change="v$.form.sex.$touch"
            />
            <label for="female">Ж</label>
          </div>
          <small v-if="v$.form.sex.required.$invalid" class="p-error">{{
            v$.form.sex.required.$message
          }}</small>
        </div>
      </Fieldset>


      <template v-if="formType === 'form-long-web'">
        <Fieldset :toggleable="true" :collapsed="true" legend="Паспортные данные">
      
        <div class="f-wrapper f-margin-top">
          <div class="label-wrapper">
          <div class="f-birthplace  f-direction-column p-float-label">
          <InputText id="birthplace" type="text"
          :class="{ 'p-invalid': v$.form.birthPlace.$invalid }"
          v-model="v$.form.birthPlace.$model"
          @blur="v$.form.birthPlace.$touch" 
          />
          <label :class="{ 'p-error': v$.form.birthPlace.$invalid }" for="birthplace">Место рождения*</label>
         
          </div>
          <small
              v-if="
                v$.form.birthPlace.$invalid ||
                v$.form.birthPlace.$pending.$response
              "
              class="p-error"
              >{{
                v$.form.birthPlace.required.$message
              }}</small
            >
          <small v-else id="">гор. Красноярск (как в паспорте)</small>
        </div>
        </div>
        
        <div class="f-wrapper f-margin-top">
          <div class="label-wrapper">
          <div class="f-series f-direction-column p-float-label">
            <InputMask id="series"
            mask="9999" 
            :class="{ 'p-invalid': v$.form.series.$invalid }"
            v-model="v$.form.series.$model"
            @blur="v$.form.series.$touch"
            />
            <label :class="{ 'p-error': v$.form.series.$invalid }" for="series">Серия паспорта*</label>
            
          </div>
          <small
              v-if="
                v$.form.series.$invalid ||
                v$.form.series.$pending.$response
              "
              class="p-error"
              >{{
                v$.form.series.required.$message
              }}</small
            >
            <small v-else id="">1234</small>
        </div>
        <div class="label-wrapper">
          <div class="f-number f-direction-column p-float-label">
            <InputMask id="number" mask="999999" 
            :class="{ 'p-invalid': v$.form.number.$invalid }"
            v-model="v$.form.number.$model"
            @blur="v$.form.number.$touch"
            />
            <label :class="{ 'p-error': v$.form.number.$invalid }" for="number">Номер паспорта*</label>
            
          </div>
          <small
              v-if="
                v$.form.number.$invalid ||
                v$.form.number.$pending.$response
              "
              class="p-error"
              >{{
                v$.form.number.required.$message
              }}</small
            >
            <small v-else id="">123456</small>
        </div>
        </div>
        <div class="f-wrapper f-margin-top">
          <div class="label-wrapper">
          <div class="f-series f-direction-column p-float-label">
            <InputMask id="fmscode" mask="999-999" 
            :class="{ 'p-invalid': v$.form.fmsCode.$invalid }"
            v-model="v$.form.fmsCode.$model"
            @blur="v$.form.fmsCode.$touch"
            />
            <label :class="{ 'p-error': v$.form.fmsCode.$invalid }" for="fmscode">Код подразделения*</label>
            
          </div>
          <small
              v-if="
                v$.form.fmsCode.$invalid ||
                v$.form.fmsCode.$pending.$response
              "
              class="p-error"
              >{{
                v$.form.fmsCode.required.$message
              }}</small
            >
            <small v-else id="">123-456</small>
        </div>
        <div class="label-wrapper">
          <div class="f-number f-direction-column p-float-label">
            <InputMask
              id="issuedate"
              mask="99.99.9999"
              :class="{ 'p-invalid': v$.form.issueDate.$invalid }"
              v-model="v$.form.issueDate.$model"
              @blur="v$.form.issueDate.$touch"
            />
            <label for="issuedate">Дата выдачи*</label>
            
          </div>
          <small v-if="v$.form.issueDate.required.$invalid" class="p-error">{{
              v$.form.issueDate.required.$message
            }}</small>
            <small
              v-else-if="v$.form.issueDate.issueDateValidate.$invalid"
              class="p-error"
              >{{ v$.form.issueDate.issueDateValidate.$message }}</small
            >
            <small v-else id="">20.01.2010</small>
        </div>
        </div>
      </Fieldset>
    </template>
      <template v-if="formType === 'form-long-web'">
        <Fieldset :toggleable="true" :collapsed="true" legend="Адрес проживания">
      
        <div class="f-wrapper">
          <div class="label-wrapper">
          <div class="f-region f-direction-column p-float-label">
            <AutoComplete
              id="region"
              :suggestions="regionSuggestOptions"
              @complete="fetchRegionSuggest($event.query)"
              :class="{ 'p-invalid': v$.form.region.$invalid }"
              v-model="v$.form.region.$model"
              @blur="v$.form.region.$touch"
            />
            <label for="lastname">Регион*</label>
            
          </div>
          <small
              v-if="
                v$.form.region.$invalid ||
                v$.form.region.$pending.$response
              "
              class="p-error"
              >{{
                v$.form.region.required.$message
              }}</small
            >
            <small v-else id="">Красноярский край</small>
        </div>
        <div class="label-wrapper">
          <div class="f-city f-direction-column p-float-label">
            <AutoComplete
              id="city"
              :suggestions="citySuggestOptions"
              @complete="fetchCitySuggest($event.query)"
              :class="{ 'p-invalid': v$.form.city.$invalid }"
              v-model="v$.form.city.$model"
              @blur="v$.form.city.$touch"
            />
            <label for="city">Город*</label>
            
          </div>
          <small
              v-if="
                v$.form.city.$invalid ||
                v$.form.city.$pending.$response
              "
              class="p-error"
              >{{
                v$.form.city.required.$message
              }}</small
            >
            <small v-else id="">Красноярск</small>
        </div>
        </div>
        
        <div class="f-wrapper f-margin-top">
          <div class="label-wrapper">
          <div class="f-regstreet f-direction-column p-float-label">
            <InputText id="regstreet" type="text"
            :class="{ 'p-invalid': v$.form.regStreet.$invalid }"
            v-model="v$.form.regStreet.$model"
            @blur="v$.form.regStreet.$touch"
            />
            <label for="regstreet">Улица*</label>
            
          </div>
          <small
              v-if="
                v$.form.regStreet.$invalid ||
                v$.form.regStreet.$pending.$response
              "
              class="p-error"
              >{{
                v$.form.regStreet.required.$message
              }}</small
            >
            <small v-else id="">Восточная</small>
        </div>
        <div class="label-wrapper">
          <div class="f-regstreet f-direction-column p-float-label">
            <InputText id="reghouse" type="text"
            :class="{ 'p-invalid': v$.form.regHouse.$invalid }"
            v-model="v$.form.regHouse.$model"
            @blur="v$.form.regHouse.$touch"
            />
            <label for="reghouse">Дом*</label>
            
          </div>
          <small
              v-if="
                v$.form.regHouse.$invalid ||
                v$.form.regHouse.$pending.$response
              "
              class="p-error"
              >{{
                v$.form.regHouse.required.$message
              }}</small
            >
            <small v-else id="">5</small>
        </div>
        </div>
        <div class="f-wrapper f-margin-top">
          <div class="label-wrapper">
          <div class="f-regblock f-direction-column p-float-label">
            <InputText id="regblock" type="text" v-model="form.regBlock" />
            <label for="regblock">Корпус (при наличии)</label>
          </div>
          <small id="">2</small>
        </div>
        <div class="label-wrapper">
          <div class="f-regapartment f-direction-column p-float-label">
            <InputText
              id="regapartment"
              type="text"
              v-model="form.regApartment"
            />
            <label for="regapartment">Квартира (при наличии)</label>
            
          </div>
          <small id="">25</small>
        </div>
        </div>
      </Fieldset>
      </template>
      <template v-if="formType === 'form-short-web'">
        <Fieldset :toggleable="true" :collapsed="true" legend="Адрес проживания">
      
        <div class="f-wrapper">
          <div class="label-wrapper">
          <div class="f-region f-direction-column p-float-label">
            <AutoComplete
              id="region"
              :suggestions="regionSuggestOptions"
              @complete="fetchRegionSuggest($event.query)"
              :class="{ 'p-invalid': v$.form.region.$invalid }"
              v-model="v$.form.region.$model"
              @blur="v$.form.region.$touch"
            />
            <label for="lastname">Регион*</label>
            
          </div>
          <small
              v-if="
                v$.form.region.$invalid ||
                v$.form.region.$pending.$response
              "
              class="p-error"
              >{{
                v$.form.region.required.$message
              }}</small
            >
            <small v-else id="">Красноярский край</small>
        </div>
        <div class="label-wrapper">
          <div class="f-city f-direction-column p-float-label">
            <AutoComplete
              id="city"
              :suggestions="citySuggestOptions"
              @complete="fetchCitySuggest($event.query)"
              :class="{ 'p-invalid': v$.form.city.$invalid }"
              v-model="v$.form.city.$model"
              @blur="v$.form.city.$touch"
            />
            <label for="city">Город*</label>
            
          </div>
          <small
              v-if="
                v$.form.city.$invalid ||
                v$.form.city.$pending.$response
              "
              class="p-error"
              >{{
                v$.form.city.required.$message
              }}</small
            >
            <small v-else id="">Красноярск</small>
        </div>
        </div>
        
        
        
      </Fieldset>
    </template>
    
      
    <!-- </Accordion> -->
    <div class="field-checkbox">
      <Checkbox v-model="form.consent" :binary="true" />
      <label class="">
        <small v-html="getConsentText()"></small>
      </label>
    </div>

    <Button
      @click="next"
      :disabled="!form.consent"
      label="ПРОДОЛЖИТЬ"
    />
  </form>
</template>

    <script>
import Button from "primevue/button";
import AutoComplete from "primevue/autocomplete";
import Accordion from "primevue/accordion";
import Fieldset from 'primevue/fieldset';
import AccordionTab from "primevue/accordiontab";
import InputMask from "primevue/inputmask";
import InputText from "primevue/inputtext";
import RadioButton from "primevue/radiobutton";
import Checkbox from "primevue/checkbox";
import InputNumber from 'primevue/inputnumber';
import { debounce } from "debounce";
import vSelect from "@/../node_modules/vue-select/src/index.js";
import * as Bid from "@/assets/js/bid/default_create.js";
import * as Suggest from "@/assets/js/suggest.js";
import * as Validate from "@/assets/js/validators.js";
import * as GetParams from "@/assets/js/get_params.js";
import * as DataParams from "@/assets/js/data_params.js";
import * as Goal from "@/assets/js/goal.js";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  name: "FormWeb",

  components: {
    vSelect,
    Button,
    Checkbox,
    RadioButton,
    InputText,
    InputMask,
    AutoComplete,
    Accordion,
    Fieldset,
    AccordionTab,
    InputNumber
  },

  props: {
    formType: String,
    forwardPage: Function,
    backwardPage: Function,
    previousPagePromise: Promise,
  },

  setup: () => ({ v$: useVuelidate() }),

  validations() {
    return {
      form: {
        phone: {
          required: helpers.withMessage("Поле обязательно", required),
          $lazy: true,
        },
        email: {
          required: helpers.withMessage("Поле обязательно", required),
          $lazy: true,
        },
        lastname: {
          required: helpers.withMessage("Поле обязательно", required),
          $lazy: true,
        },
        firstname: {
          required: helpers.withMessage("Поле обязательно", required),
          $lazy: true,
        },
        secondname: {
          required: helpers.withMessage("Поле обязательно", required),
          $lazy: true,
        },
        dob: {
          dobValidate: helpers.withMessage(
            "Введите корректную дату",
            Validate.validate18Years
          ),
          required: helpers.withMessage(
            "Поле обязательно",
            required
          ),
          $lazy: true,
        },
        sex: {
          required: helpers.withMessage("Поле обязательно", required),
          $lazy: true,
        },
        birthPlace: {
          required: helpers.withMessage("Поле обязательно", required),
          $lazy: true,
        },
        series:{
        required: helpers.withMessage("Поле обязательно", required),
        $lazy: true,
        },
        number:{
        required: helpers.withMessage("Поле обязательно", required),
        $lazy: true,
        },
        fmsCode:{
        required: helpers.withMessage("Поле обязательно", required),
        $lazy: true,
        },
        issueDate: {
          issueDateValidate: helpers.withMessage(
            "Введите корректную дату",
            Validate.date
          ),
          required: helpers.withMessage(
            "Поле обязательно",
            required
          ),
          $lazy: true,
        },
        region: {
          required: helpers.withMessage("Поле обязательно", required),
          $lazy: true,
        },
        city: {
          required: helpers.withMessage("Поле обязательно", required),
          $lazy: true,
        },
        regStreet: {
          required: helpers.withMessage("Поле обязательно", required),
          $lazy: true,
        },
        regHouse: {
          required: helpers.withMessage("Поле обязательно", required),
          $lazy: true,
        },
        amount: {
          required: helpers.withMessage("Поле обязательно", required),
          $lazy: true,
        },
        period: {
          required: helpers.withMessage("Поле обязательно", required),
          $lazy: true,
        },
      },
    };
  },

  data: () => ({
    activeIndex:0,
    consentTextDefault:
      'Даю свое согласие на <a href="/docs/Soglasie_na_obrabotku_personalnyh_dannyh.pdf" target="_blank">обработку персональных данных</a>, на <a href="/docs/Soglasie_na_poluchenie_reklamnyh_materialov.pdf" target="_blank">получение</a> рекламных материалов. Соглашаюсь с условиями <a href="/docs/Oferta.pdf" target="_blank">оферты</a>. Я принял соглашение на оформление <a href="/docs/Prilozhenie_4_Soglashenie_na_oformlenie_platnoj_podpiski.pdf" target="_blank">платной подписки</a>. Для активации сервиса будет списан 1 рубль, дальнейшее использование сервиса будет проходит в соответствии с <a href="/docs/Prilozhenie_2_Informacija_o_dejstvujushhih_tarifah.pdf" target="_blank">тарифами сервиса</a>. Стоимость услуги четыреста девяносто восемь рублей каждые 5 дней по подписке. Подписка не гарантирует получение займа! <span style="color:red;">* </span>',
    
    isNextClicked: false,
    isPartialSubmitted: false,
    sendPromise: Promise.resolve(),
    regionSuggestOptions: [],
    citySuggestOptions: [],
    firstnameSuggestOptions: [],
    lastnameSuggestOptions: [],
    secondnameSuggestOptions: [],
    emailSuggestOptions: [],
    totalWidth: 0,
    form: {
      phone: null,
      email: null,
      lastname: null,
      firstname: null,
      secondname: null,
      dob: null,
      birthPlace: null,
      region: null,
      city: null,
      cityKladr: null,
      regionKladr: null,
      sex: null,
      amount: GetParams.get("amount"),
      period: GetParams.get("period"),
      series: null,
      number: null,
      fmsCode: null,
      fmsUnit: null,
      issueDate: null,
      consent: false,
      regHouse: null,
      regBlock: null,
      regApartment: null,
      regStreet: null,
    },
    valid: {
      phone: true,
      email: true,
      lastname: true,
      firstname: true,
      secondname: true,
      dob: true,
      birthPlace: true,
      region: true,
      city: true,
      sex: true,
      amount: true,
      period: true,
      creditHistory: true,
      series: true,
      number: true,
      fmsCode: true,
      fmsUnit: true,
      issueDate: true,
      consent: true,
      regRegion: true,
      regCity: true,
      regStreet: true,
      regHouse: true,
    },
  }),

  methods: {
    getConsentText() {
      return window.consent || this.consentTextDefault;
    },
    //Нажатие на кнопку "продолжить"
    async next() {
      this.isNextClicked = true;
      const isValid = await this.validate();
      if (!isValid) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        return
      }
      console.log(isValid);
      this.submit();
      
    },

    //Валидация формы
    async validate() {
      const result = await this.v$.$validate();
      if (!result) {
        // notify user form is invalid
        return false;
      }

      //Не валидируем, пока не была нажата кнопка далее
      if (this.isNextClicked === false) {
        return false;
      }
      return true;
    },

    //Отправка данных на сервер, когда были введены валидные телефон и email
    sendPartial() {
      //Если данные уже отправлены
      if (this.isPartialSubmitted === true) {
        return true;
      }
      if (
        !Validate.phone(this.form.phone) ||
        !Validate.email(this.form.email) ||
        !Validate.noempty(this.form.firstname) ||
        !Validate.noempty(this.form.lastname) ||
        !Validate.noempty(this.form.amount)
      ) {
        return;
      }

      this.isPartialSubmitted = true;
      this.sendPromise = Bid.send(this.form);
    },

    //Отправка формы
    submit() {

      Goal.newBid();
      this.sendPromise = this.sendPromise.then(() => Bid.send(this.form));
      this.forwardPage(this.sendPromise);
    console.log(this.form)
    },

    //Получения названия подразделения по коду
    getFmsUnit() {
      Suggest.suggestFmsCode(this.form.fmsCode)
        .then((data) => {
          this.form.fmsUnit = data;
          if (!data) {
            this.form.fmsCode = null;
          }
        })
        .catch(() => {
          this.form.fmsCode = null;
        });
    },
    //Region suggestions

    fetchRegionSuggest: debounce(function (search, update) {
      if (search.length < 2) {
        this.regionSuggestOptions = [];
        return;
      }
      Suggest.suggestRegion(search)
        .then((data) => {
          if (data.length)
            this.regionSuggestOptions = data.map((el) => el.text);
          else this.regionSuggestOptions = [search];
        })
        .catch(() => {
          this.regionSuggestOptions = [search];
        });
    }, 500),

    //City suggestions

    fetchCitySuggest: debounce(function (search, update) {
      if (search.length < 2) {
        this.citySuggestOptions = [];
        return;
      }
      Suggest.suggestCity(search)
        .then((data) => {
          if (data.length) this.citySuggestOptions = data.map((el) => el.text);
          else this.citySuggestOptions = [search];
        })
        .catch(() => {
          this.citySuggestOptions = [search];
        });
    }, 500),

    //Fio suggestions
    fetchFirstname: debounce(function (search, update) {
      if (search.length < 2) {
        this.firstnameSuggestOptions = [search];
        return;
      }
      this.fetchFioSuggest(search, update, "name");
    }, 500),

    fetchLastname: debounce(function (search, update) {
      if (search.length < 2) {
        this.lastnameSuggestOptions = [search];
        return;
      }
      this.fetchFioSuggest(search, update, "surname");
    }, 500),

    fetchSecondname: debounce(function (search, update) {
      if (search.length < 2) {
        this.secondnameSuggestOptions = [search];
        return;
      }
      this.fetchFioSuggest(search, update, "patronymic");
    }, 500),

    fetchFioSuggest(search, update, type) {
      Suggest.suggestFio(search, type)
        .then((data) => {
          if (type === "name") {
            this.firstnameSuggestOptions = data.map((el) => el.text);
          }
          if (type === "surname") {
            this.lastnameSuggestOptions = data.map((el) => el.text);
          }
          if (type === "patronymic") {
            this.secondnameSuggestOptions = data.map((el) => el.text);
          }
        })
        .catch(() => {
          if (type === "name") {
            this.firstnameSuggestOptions = [search];
          }
          if (type === "surname") {
            this.lastnameSuggestOptions = [search];
          }
          if (type === "patronymic") {
            this.secondnameSuggestOptions = [search];
          }
        });
    },
    //Email suggestions
    fetchEmailSuggest: debounce(function (search, update) {
      if (search.length < 2) {
        this.emailSuggestOptions = [];
        return;
      }
      Suggest.suggestEmail(search)
        .then((data) => {
          if (data.length) this.emailSuggestOptions = data.map((el) => el.text);
          else this.emailSuggestOptions = [search];
        })
        .catch(() => {
          this.emailSuggestOptions = [search];
        });
    }, 500),
  },

  mounted() {




    // document.getElementById('anketa-title').innerHTML= `<div style="text-align: center;margin-bottom:50px">
    //     <h3>Оставь заявку на займ прямо сейчас!</h3>
    //     <h5>Работаем полностью онлайн, без справок и поручителей, даже с плохой кредитной историей.</h5>
    //   </div>`
    //Подстановка geo по ip
    Suggest.getGeoFromIp().then((geo) => {
      if (geo.cityKladr === null) {
        return;
      }
      this.form.region = geo.region;
      this.form.regionKladr = geo.regionKladr;
      this.form.city = geo.city;
      this.form.cityKladr = geo.cityKladr;
      this.regionSuggestOptions[this.form.region] = this.form.regionKladr;
      this.citySuggestOptions[this.form.city] = this.form.cityKladr;
    });
  },
};
</script>
    
    