<style scoped>
    .radios {
        display: flex;
        align-items: center;
        height: calc(1.5em + 0.75rem + 2px);
    }

    .button-wrapper {
        justify-content: flex-end;
    }

    @media (max-width: 767px) {
        .button-wrapper {
            justify-content: center;
        }
    }
    .progress {
    height:30px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
    display: flex;
    align-items: center;
}
.progress__bar {	
    transition: width 300ms ease-in-out;
    height:30px;
    border-radius: 6px; 
    transition: 0.4s linear;  
    transition-property: width, background-color;
    background: linear-gradient(45deg, rgba(2,0,87,1) 0%, rgba(0,183,259,10) 0%, rgba(0,226,247,25) 100%);
    
}

</style>

<template>
    <div class="row">
        <form class="col-md-12" novalidate @submit="submit">
            <h4 class="text-center pt-3 pb-2">Контактная информация</h4>
            <div class="row">
                <div class="col-md-6 pb-2">
                    <label for="anketa-phone">Телефон</label>
                    <input type="text" class="form-control" id="anketa-phone" v-mask="{mask: '+7 (999) 999-9999'}" :class="{'is-invalid': !valid.phone}" v-model="form.phone" @change="validate(); sendPartial();" autocomplete="off" placeholder="+7 (___) ___-____" @blur='progressbar();'>
                </div>
                <div class="col-md-6 pb-2">
                    <label>Email</label>                    
                    <v-select
                        class="form-control"
                        :class="{'is-invalid': !valid.email}"
                        v-model="form.email"
                        :filterable="false"
                        :options="Object.keys(emailSuggestOptions)"
                        @search="fetchEmailSuggest"
                        id="anketa-lastname"
                        @change="validate(); sendPartial();progressbar();"
                    >
                        <template v-slot:no-options="{ search, searching }">Начните ввод</template>
                    </v-select>
                </div>
            </div>

            <h4 class="text-center pt-3">Личные данные</h4>
            <div class="row">
                <div class="col-md-6 pb-2">
                    <label>Фамилия</label>
                    <v-select
                        class="form-control"
                        :class="{'is-invalid': !valid.lastname}"
                        v-model="form.lastname"
                        :filterable="false"
                        :options="Object.keys(lastnameSuggestOptions)"
                        @search="fetchLastname"
                        id="anketa-lastname"
                        @change="validate;sendPartial();progressbar();"
                    >
                        <template v-slot:no-options="{ search, searching }">Начните ввод</template>
                    </v-select>
                </div>
                <div class="col-md-6 pb-2">
                    <label>Имя</label>
                    <v-select
                        class="form-control"
                        :class="{'is-invalid': !valid.firstname}"
                        v-model="form.firstname"
                        :filterable="false"
                        :options="Object.keys(firstnameSuggestOptions)"
                        @search="fetchFirstname"
                        id="anketa-firstname"
                        @change="validate; sendPartial();progressbar();"
                    >
                        <template v-slot:no-options="{ search, searching }">Начните ввод</template>
                    </v-select>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 pb-2">
                    <label>Отчество</label>
                    <v-select
                        class="form-control"
                        :class="{'is-invalid': !valid.secondname}"
                        v-model="form.secondname"
                        :filterable="false"
                        :options="Object.keys(secondnameSuggestOptions)"
                        @search="fetchSecondname"
                        id="anketa-firstname"
                        @change="validate;progressbar();"
                    >
                        <template v-slot:no-options="{ search, searching }">Начните ввод</template>
                    </v-select>
                </div>
                <div class="col-md-6 pb-2">
                    <label for="anketa-dob">Дата рождения</label>
                    <input type="text" class="form-control" id="anketa-dob" v-mask="{mask: '99.99.9999'}" :class="{'is-invalid': !valid.dob}" v-model="form.dob" @change="validate" autocomplete="off" placeholder="ДД.ММ.ГГГГ" @blur='progressbar();'>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 pb-2">
                    <label for="anketa-birthPlace">Место рождения</label>
                    <input type="text" class="form-control" id="anketa-birthPlace" :class="{'is-invalid': !valid.birthPlace}" v-model="form.birthPlace" @change="validate" autocomplete="off" @blur='progressbar();'>
                </div>
                <div class="col-md-6 pb-2">
                    <label>Пол</label>
                    <div class="radios">
                        <div class="custom-control custom-radio mr-4">
                            <input type="radio" id="anketa-male" class="custom-control-input" name='checkbox' :class="{'is-invalid': !valid.sex}" value="male" v-model="form.sex" @change="validate" @blur='progressbar();'>
                            <label class="custom-control-label" for="anketa-male">М</label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input type="radio" id="anketa-female" class="custom-control-input" name='checkbox' :class="{'is-invalid': !valid.sex}" value="female" v-model="form.sex" @change="validate" @blur='progressbar();'>
                            <label class="custom-control-label" for="anketa-female">Ж</label>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row">
                <div class="col-md-6 pb-2">
                   <label>Регион</label>
                    <v-select
                        class="form-control"
                        :class="{'is-invalid': !valid.region}"
                        v-model="form.region"
                        :filterable="false"
                        :options="Object.keys(regionSuggestOptions)" 
                        @search="fetchRegionSuggest"
                        id="anketa-region"
                        @blur='progressbar();'
                    >
                        <template v-slot:no-options="{ search, searching }">Регион не найден</template>
                    </v-select>
                </div>
                <div class="col-md-6 pb-2">
                    <label>Город</label>
                    <v-select
                        class="form-control"
                        :class="{'is-invalid': !valid.city}"
                        v-model="form.city"
                        :filterable="false"
                        :options="Object.keys(citySuggestOptions)" 
                        @search="fetchCitySuggest"
                        id="anketa-location"
                        @blur='progressbar();'
                    >
                        <template v-slot:no-options="{ search, searching }">Город не найден</template>
                    </v-select>
                </div>
            </div>
            <template v-if="formType === 'default-free'">
                <h4 class="text-center pt-3">Паспортные данные</h4>
                <div class="row">
                    <div class="col-md-6 pb-2">
                        <label for="anketa-series">Серия</label>
                        <input type="text"
                               class="form-control"
                               id="anketa-series"
                               v-mask="{mask: '9999'}"
                               :class="{'is-invalid': !valid.series}"
                               v-model="form.series"
                               @change="validate"
                               autocomplete="off"
                               @blur='progressbar();'>
                    </div>
                    <div class="col-md-6 pb-2">
                        <label for="anketa-number">Номер</label>
                        <input type="text"
                               class="form-control"
                               id="anketa-number"
                               v-mask="{mask: '999999'}"
                               :class="{'is-invalid': !valid.number}"
                               v-model="form.number"
                               @change="validate"
                               autocomplete="off"
                               @blur='progressbar();'>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 pb-2">
                        <label for="anketa-fmsCode">Код</label>
                        <input type="text"
                               class="form-control"
                               id="anketa-fmsCode"
                               v-mask="{mask: '999-999'}"
                               :class="{'is-invalid': !valid.fmsCode}"
                               @focusout="getFmsUnit"
                               v-model="form.fmsCode"
                               @change="validate"
                               autocomplete="off"
                               @blur='progressbar();'>
                    </div>
                    <div class="col-md-6 pb-2">
                        <label for="anketa-issueDate">Дата выдачи</label>
                        <input type="text"
                               class="form-control"
                               v-mask="{mask: '99.99.9999'}"
                               :class="{'is-invalid': !valid.issueDate}"
                               id="anketa-issueDate"
                               v-model="form.issueDate"
                               @change="validate"
                               autocomplete="off"
                               @blur='progressbar();'>
                    </div>
                </div>
            </template>

            <h4 class="text-center pt-3">Параметры займа</h4>
            <div class="row mb-2 pb-2">
                <div class="col-md-6">
                    <label for="anketa-amount">Сумма займа (рубли)</label>
                    <input type="text" class="form-control" id="anketa-amount" v-mask="{regex: '\\d*'}" :class="{'is-invalid': !valid.amount}" v-model="form.amount" @change="validate(); sendPartial()" autocomplete="off" @blur='progressbar();'>
                </div>
                <div class="col-md-6 pb-2">
                    <label for="anketa-period">Срок займа (дни)</label>
                    <input type="text" class="form-control" id="anketa-period" v-mask="{regex: '\\d*'}" :class="{'is-invalid': !valid.period}" v-model="form.period" @change="validate" autocomplete="off" @blur='progressbar();'>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 pb-2">
                    <label for="anketa-creditHistory">Кредитная история</label>
                    <select class="custom-select d-block w-100" id="anketa-creditHistory" v-model="form.creditHistory">
                        <option v-for="item in creditHistoryItems" :value="item.id">{{ item.value }}</option>
                    </select>
                </div>
            </div>

            <div class="row" style="padding-top:2em;">
                <div class="col-md-12 pb-2">
                    <div class="custom-control custom-checkbox anketa-control-consent">
                        <input type="checkbox" class="custom-control-input" id="anketa-consent" name='checkbox' :class="{'is-invalid': !valid.consent}" v-model="form.consent" @change="validate" autocomplete="off" @blur='progressbar();'>
                        <label class="custom-control-label" for="anketa-consent">
                            <small style="vertical-align: text-top;" v-html="getConsentText()"></small>
                        </label>
                    </div>
                </div>
            </div>

            <div class="row pt-3 pb-4">
                <div class="col-md-12 d-flex button-wrapper">
                    <button class="btn btn-primary btn-lg btn-block" style="width: 200px;" @click="next">Продолжить</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { debounce } from 'debounce';
import vSelect from '@/../node_modules/vue-select/src/index.js';
import * as Bid from '@/assets/js/bid/default_create.js';
import * as Suggest from '@/assets/js/suggest.js';
import * as Validate from '@/assets/js/validators.js';
import * as GetParams from '@/assets/js/get_params.js';
import * as DataParams from '@/assets/js/data_params.js';
import * as Goal from '@/assets/js/goal.js';

export default {
    name: 'FormDefault',
    components: { vSelect },

    props: {
        formType: String,
        forwardPage: Function,
        backwardPage: Function,
        previousPagePromise: Promise,
    },

    data: () => ({
        consentTextDefault: 'Даю свое согласие на <a href="/docs/Soglasie_na_obrabotku_personalnyh_dannyh.pdf" target="_blank">обработку персональных данных</a>, на <a href="/docs/Soglasie_na_poluchenie_reklamnyh_materialov.pdf" target="_blank">получение</a> рекламных материалов. Соглашаюсь с условиями <a href="/docs/Oferta.pdf" target="_blank">оферты</a>. Я принял соглашение на оформление <a href="/docs/Prilozhenie_4_Soglashenie_na_oformlenie_platnoj_podpiski.pdf" target="_blank">платной подписки</a>. Для активации сервиса будет списан 1 рубль, дальнейшее использование сервиса будет проходит в соответствии с <a href="/docs/Prilozhenie_2_Informacija_o_dejstvujushhih_tarifah.pdf" target="_blank">тарифами сервиса</a>. Стоимость услуги четыреста девяносто восемь рублей каждые 5 дней по подписке. Подписка не гарантирует получение займа!',
        isNextClicked: false,
        isPartialSubmitted: false,
        sendPromise: Promise.resolve(),
        regionSuggestOptions: [],
        citySuggestOptions: [],
        firstnameSuggestOptions: [],
        lastnameSuggestOptions: [],
        secondnameSuggestOptions: [],
        emailSuggestOptions: [],
        totalWidth: 0,
        form: {
            phone: null,
            email: null,
            lastname: null,
            firstname: null,
            secondname: null,
            dob: null,
            birthPlace: null,
            region: null,
            city: null,
            cityKladr: null,
            regionKladr: null,
            sex: null,
            amount: GetParams.get('amount'),
            period: GetParams.get('period'),
            creditHistory: 0,
            series: null,
            number: null,
            fmsCode: null,
            fmsUnit: null,
            issueDate: null,
            consent: false,
        },
        valid: {
            phone: true,
            email: true,
            lastname: true,
            firstname: true,
            secondname: true,
            dob: true,
            birthPlace: true,
            region: true,
            city: true,
            sex: true,
            amount: true,
            period: true,
            creditHistory: true,
            series: true,
            number: true,
            fmsCode: true,
            fmsUnit: true,
            issueDate: true,
            consent: true,
        },
        creditHistoryItems: [
            {id: 0, value: 'Нет кредитной истории'},
            {id: 1, value: 'Был займ, просрочек не было'},
            {id: 2, value: 'Был займ, просрочки были'},
            {id: 3, value: 'Открытый займ и просрочки'},
            {id: 4, value: 'Работают коллекторы'}
        ],
    }),

    methods: {
        progressbar(){                
            const input = document.getElementsByTagName('input');
            const allInputs = input.length;
            let touchedInput = 3;
            console.log(allInputs)
            for (let i = 0; i < allInputs; i++) {
                console.log(input[i].value, input[i].name);
                if ((input[i].value  && input[i].name !== 'checkbox') || (input[i].checked && input[i].name === 'checkbox')) {
                    touchedInput++;
                }
            }
            console.log(touchedInput);
            this.totalWidth = Math.round(touchedInput / allInputs * 100);
        },
        
        getConsentText() {
            return window.consent || this.consentTextDefault;
        },

        //Нажатие на кнопку "продолжить"
        next(e) {
            this.isNextClicked = true;
            if(!this.validate()) {
                e.preventDefault();
            }
        window.scrollTo({ top: 0, behavior: 'smooth' });
        },

        //Валидация формы
        validate() {
            //Не валидируем, пока не была нажата кнопка далее
            if (this.isNextClicked === false) {
                return true;
            }

            this.valid.phone = Validate.phone(this.form.phone);
            this.valid.email = Validate.email(this.form.email);
            this.valid.lastname = Validate.noempty(this.form.lastname);
            this.valid.firstname = Validate.noempty(this.form.firstname);
            this.valid.secondname = Validate.noempty(this.form.secondname);
            this.valid.dob = Validate.validate18Years(this.form.dob);
            this.valid.birthPlace = Validate.noempty(this.form.birthPlace);
            this.valid.region = Validate.noempty(this.form.region);
            this.valid.city = Validate.noempty(this.form.city);
            this.valid.sex = Validate.noempty(this.form.sex);
            this.valid.amount = Validate.noempty(this.form.amount);
            this.valid.period = Validate.noempty(this.form.period);
            this.valid.consent = this.form.consent;

            if (this.formType === 'default-free') {
                this.valid.series = Validate.series(this.form.series);
                this.valid.number = Validate.number(this.form.number);
                this.valid.fmsCode = Validate.fmsCode(this.form.fmsCode);
                this.valid.issueDate = Validate.date(this.form.issueDate);
            }

            for (var field in this.valid) {
                if (this.valid[field] === false) {
                    return false;
                }
            }

            return true;
        },

        //Отправка данных на сервер, когда были введены валидные телефон и email
        sendPartial() {
            //Если данные уже отправлены
            if (this.isPartialSubmitted === true) {
                return true;
            }
            if (!Validate.phone(this.form.phone) ||
                !Validate.email(this.form.email) ||
                !Validate.noempty(this.form.firstname) ||
                !Validate.noempty(this.form.lastname) ||
                !Validate.noempty(this.form.amount)) {
                return;
            }

            this.isPartialSubmitted = true;
            this.sendPromise = Bid.send(this.form);
        },

        //Отправка формы
        submit(e) {
            e.preventDefault();
            Goal.newBid();
            this.sendPromise = this.sendPromise.then(() => Bid.send(this.form));
            this.forwardPage(this.sendPromise);
        },

        //Получения названия подразделения по коду
        getFmsUnit() {
                Suggest.suggestFmsCode(this.form.fmsCode).then(data => {
                    this.form.fmsUnit = data;
                    if(!data){
                        this.form.fmsCode = null
                    }
                })
                .catch(()=>
                {
                    this.form.fmsCode = null
                }
                )
            },
        //Region suggestions
        fetchRegionSuggest: debounce(function(search, loading) {
            if(search.length < 2) {
                this.form.regionKladr = this.regionSuggestOptions[this.form.region];
                this.citySuggestOptions = {};
                this.form.city = null;
                this.form.cityKladr = null;
                return;
            }
            loading(true);
            Suggest.suggestRegion(search).then(data => {
                this.regionSuggestOptions = {};
                data.forEach(el => this.regionSuggestOptions[el.text] = el.id);
                loading(false);
            });
        }, 500),

        //City suggestions
        fetchCitySuggest: debounce(function(search, loading) {
            if(search.length < 2) {
                this.form.cityKladr = this.citySuggestOptions[this.form.city];
                return;
            }
            loading(true);
            Suggest.suggestCity(search, this.form.regionKladr).then(data => {
                this.citySuggestOptions = {};
                data.forEach(el => this.citySuggestOptions[el.text] = el.id);
                loading(false);
            });
        }, 500),
        
        //Fio suggestions
        fetchFirstname: debounce(function (search, loading) {
            if(search.length < 2) {
                this.form.firstname = this.firstnameSuggestOptions[this.form.firstname];
                return;
            }
            this.fetchFioSuggest(search,loading, 'name')
        }, 500),
        
        fetchLastname: debounce(function (search, loading) {
            if(search.length < 2) {
                this.form.lastname = this.lastnameSuggestOptions[this.form.lastname];
                return;
            }
            this.fetchFioSuggest(search,loading, 'surname')
        }, 500),
        
        fetchSecondname: debounce(function (search, loading) {
            if(search.length < 2) {
                this.form.secondname = this.secondnameSuggestOptions[this.form.secondname];
                return;
            }
            this.fetchFioSuggest(search,loading, 'patronymic')
        }, 500),
        
        fetchFioSuggest(search, loading, type) {
            loading(true);
            Suggest.suggestFio(search, type).then(data => {
                if (type === 'name') {
                    console.log(data);
                    this.firstnameSuggestOptions = {};
                    data.forEach(el => this.firstnameSuggestOptions[el.text] = el.id);
                    loading(false)
                }
                if (type === 'surname') {
                    console.log(data);
                    this.lastnameSuggestOptions = {};
                    data.forEach(el => this.lastnameSuggestOptions[el.text] = el.id);
                    loading(false)
                }
                if (type === 'patronymic') {
                    console.log(data);
                    this.secondnameSuggestOptions = {};
                    data.forEach(el => this.secondnameSuggestOptions[el.text] = el.id);
                    loading(false)
                }
            });
        },
        //Email suggestions
        fetchEmailSuggest: debounce(function(search, loading) {
            if(search.length < 2) {
                this.emailSuggestOptions = {};
                return;
            }
            loading(true);
            Suggest.suggestEmail(search).then(data => {
                this.emailSuggestOptions = {};
                data.forEach(el => this.emailSuggestOptions[el.text] = el.id);
                loading(false);
            });
        }, 500),
    },

    mounted () {
        
        
        //Подстановка geo по ip
        Suggest.getGeoFromIp().then(geo => {
            if (geo.cityKladr === null) {
                return;
            }
            this.form.region = geo.region;
            this.form.regionKladr = geo.regionKladr;
            this.form.city = geo.city;
            this.form.cityKladr = geo.cityKladr;
            this.regionSuggestOptions[this.form.region] = this.form.regionKladr;
            this.citySuggestOptions[this.form.city] = this.form.cityKladr;
        });
    }
}
</script>
