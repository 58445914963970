<style scoped>
.bg-green {
  background: linear-gradient(
    90deg,
    rgba(17, 147, 167, 1) 0%,
    rgba(49, 187, 56, 1) 100%
  ) !important;
}
@media (max-width: 500px) {
  .data-text {
    align-items: center;
   
  }
  .data-text-wrapper{
   padding: 10px 0px;
  }
}
</style>
<style lang="scss">

img {
  max-width: 100%;
}
@media (max-width: 500px) {

  .data-text {
    flex-direction: column;
    
  }
  .q-btn--rounded {
    .block {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}
</style>

<template>
  <form>
    <div class="data-text-top data-text">
      <div class="data-text-wrapper">
        <div class="data-sum">
          <div class="data-title">Сумма:</div>
          <div class="data-subtitle">{{ this.formatSum }} рублей</div>
        </div>
        <div class="data-period">
          <div class="data-title">Срок:</div>
          <div class="data-subtitle custom-ml">{{ period }} дней</div>
        </div>
      </div>
    </div>
   
    <div class="col steps-bg">
      <div class="step-wrapper">
        <div class="step-wrapper-percent">
          <div class="">
            <div>
              <img class="" :src="require('../assets/img/1st-step.png')" />
            </div>
          </div>
          <div class="percentage-1">
            <div class="percentage-title">
              Одобрение
              <div>+40%</div>
            </div>
          </div>
        </div>

        <div class="first-line-wrapper">
          <img
            class="first-line"
            :src="require('../assets/img/just-line.png')"
          />
        </div>

        <div class="step-wrapper-percent">
          <div class="">
            <div>
              <img class="" :src="require('../assets/img/2nd-step-just.png')" />
            </div>
          </div>
          <div class="percentage-1">
            <div class="percentage-title">
              Одобрение
              <div>+35%</div>
            </div>
          </div>
        </div>
        <div class="first-line-wrapper">
          <img
            class="first-line"
            :src="require('../assets/img/just-line.png')"
          />
        </div>
        <div class="step-wrapper-percent">
          <div class="">
            <div>
              <img class="" :src="require('../assets/img/3rd-step-just.png')" />
            </div>
          </div>
          <div class="percentage-1">
            <div class="percentage-title">
              Одобрение
              <div>+25%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="step-title">
      <div class="step-title-1">Шаг 3. Паспортные данные</div>
    </div>
    <div class="first-step-wrapper">
      <div class="wrapper first-step">
        <div class="input-wrap">
          <q-input
            :color="themeColor"
            rounded
            outlined
            id="anketa-series"
            v-model="form.series"
            :rules="seriesValidate"
            label="Серия паспорта"
            ref="inputSeries"
            mask="####"
            placeholder="1234"
          />
        </div>
        <div class="input-wrap">
          <q-input
            :color="themeColor"
            rounded
            outlined
            id="anketa-number"
            v-model="form.number"
            :rules="numberValidate"
            label="Номер паспорта"
            mask="######"
            ref="inputNumber"
            placeholder="123456"
          />
        </div>
      </div>
      <div class="wrapper first-step">
        <div class="input-wrap">
          <q-input
            :color="themeColor"
            rounded
            outlined
            id="anketa-fmsCode"
            v-model="form.fmsCode"
            :rules="fmsCodeValidate"
            @focusout="getFmsUnit"
            label="Код подразделения"
            ref="inputFmsCode"
            mask="###-###"
            placeholder="123-456"
          />
        </div>
        <div class="input-wrap">
          <q-input
            :rules="issueDateValidate"
            :color="themeColor"
            rounded
            outlined
            id="anketa-issueDate"
            v-model="form.issueDate"
            label="Дата выдачи"
            ref="inputIssueDate"
            mask="##.##.####"
            placeholder="25.01.2009"
          />
        </div>
      </div>
      <div class="confirm-button-wrapper">
        <div class="confirm-button">
          <q-btn
            @click="secondStepConfirmHandler()"
            :ripple="{ center: true }"
            rounded
            :color="themeColor"
            label="Получить деньги"
            class="confirm-button-next"
          />
        </div>
        <div class="data-text-lower data-text">
            <div class="data-text-wrapper">
              <div class="data-sum">
                <div class="data-title">Сумма:</div>
                <div class="data-subtitle">{{ this.formatSum }} рублей</div>
              </div>
              <div class="data-period">
                <div class="data-title">Срок:</div>
                <div class="data-subtitle custom-ml">
                  {{ period }} дней
                </div>
              </div>
            </div>
          </div>
      </div>

      <div>{{ error }}</div>
    </div>
  </form>
</template>

<script>
import { debounce } from "debounce";
import vSelect from "@/../node_modules/vue-select/src/index.js";
import * as Bid from "@/assets/js/bid/default_create.js";
import * as ConfirmTel from "@/assets/js/confirm_tel.js";
import * as Suggest from "@/assets/js/suggest.js";
import * as Validate from "@/assets/js/validators.js";
import * as GetParams from "@/assets/js/get_params.js";
import * as DataParams from "@/assets/js/data_params.js";
import * as Goal from "@/assets/js/goal.js";

export default {
  name: "FormDefaultNewStepThreeWithParams",

  components: { vSelect },

  props: {
    formType: String,
    forwardPage: Function,
    backwardPage: Function,
    previousPagePromise: Promise,
  },

  data: () => ({
    phone: "",
    code: "",
    isShow: true,
    num: false,
    isHide: false,
    phoneValid: true,
    error: null,
    page: "phone",
    isReadonly: true,
    countDownToTime: 0,
    timeDifference: 0,
    timerOutput: null,
    interval: null,
    retry: 0,

    docs: false,
    num: false,
    phoneConfirm: false,
    showRepeatBtn: false,
    showCode: true,

    themeColor: DataParams.get("color"),
    isNextClicked: false,
    isPartialSubmitted: false,
    sendPromise: Promise.resolve(),
    emailSuggestOptions: [],
    form: {
      series: null,
      number: null,
      fmsCode: null,
      fmsUnit: null,
      issueDate: null,
      amount: GetParams.get("amount"),
      period: GetParams.get("period"),
    },
    valid: {
      series: true,
      number: true,
      fmsCode: true,
      fmsUnit: true,
      issueDate: true,
    },
  }),

  computed: {
    //validation

    seriesValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите серию паспорта",
        (val) => Validate.series(val) || "Введите полностью",
      ];
    },
    numberValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите номер паспорта",
        (val) => Validate.number(val) || "Введите полностью",
      ];
    },
    fmsCodeValidate() {
      return [
        (val) =>
          (val !== null && val !== "") || "Введите код подразделения паспорта",
        (val) => Validate.fmsCode(val) || "Введите полностью",
        (val) => !!this.form.fmsUnit || "Введите корректно",
      ];
    },
    issueDateValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите дату выдачи паспорта",
        (val) => Validate.date(val) || "Введите верную дату",
      ];
    },
    formatSum() {
      return this.$store.state.anketa.amount.toLocaleString("ru-RU");
    },
    period() {
      return this.$store.state.anketa.period;
    },

  },

  watch: {},
  methods: {
    async secondStepConfirmHandler() {
      const isValidSeries = await this.$refs.inputSeries.validate();
      const isvalidNumber = await this.$refs.inputNumber.validate();
      const isvalidFmsCode = await this.$refs.inputFmsCode.validate();
      const isvalidIssueDate = await this.$refs.inputIssueDate.validate();
      if (
        !isValidSeries ||
        !isvalidNumber ||
        !isvalidFmsCode ||
        !isvalidIssueDate
      ) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      const resp = await Bid.send(this.form);
      if (resp.success == false) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.error = resp.error;
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.forwardPage(this.sendPromise);
      }
    },
    getFmsUnit() {
      Suggest.suggestFmsCode(this.form.fmsCode)
        .then((data) => {
          this.form.fmsUnit = data;
          this.$refs.inputFmsCode.validate();
          if (!data) {
          }
        })
        .catch(() => {
          this.form.fmsCode = null;
          this.$refs.inputFmsCode.validate();
        });
    },
  },

  mounted() {},
};
</script>
