/*
 * Модуль для получения значений data параметров
 */

const script = document.currentScript || document.querySelector('script[src*="anketa.js"]');

/*
 * Получение data- параметра из подключеного скрипта
 */
var get = function(key, default_value) {
    return script.dataset[key] || default_value || null;
};

/*
 * Получить полный url для редиректа
 * url для редиректа берется из data-redirect параметра скрипта, либо, если он не указан, это /vitrina
 */
var getRedirectUrl = function() {
    var baseUrl = window.location.protocol + '//' + window.location.host;
    var redirect = get('redirect');

    if (redirect === null) {
        return baseUrl + '/vitrina';
    }

    if (redirect.substring(0, 4) === 'http') {
        return redirect;
    }

    return baseUrl + redirect;
};

/**
 * Получить базовый url, с которого загружен скрипт анкеты
 */
var getOriginUrl = function() {
    return (new URL(script.src)).origin;
};

export { get, getRedirectUrl, getOriginUrl }
