/*
 * Модуль для отправки данных формы на сервер
 */

import * as Sender from './../sender.js'

var getEndpointUrl = function() {
    return process.env.VUE_APP_API_BASE_URL + '/api/bid/create';
};

var getRequestPayload = function(form) {
    return {
        firstname: form.firstname,
        phone: form.phone,
        amount: parseInt(form.amount),
        period: parseInt(form.period) * 30,
        host: window.location.hostname,
        geoByIp: true,
        pts: {
            carBrand: form.carBrand,
            carModel: form.carModel,
            carYear: form.carYear,
        },
        utm: Sender.getUtms(),
    };
};

var send = function(form) {
    return Sender.send(getEndpointUrl(), getRequestPayload(form));
};

export { send }
