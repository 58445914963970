/*
 * Модуль для отправки данных формы на сервер
 */

import * as Sender from './../sender.js'

var getEndpointUrl = function() {
    return process.env.VUE_APP_API_BASE_URL + '/api/bid/create';
};

var getRequestPayload = function(form) {
    return {
        firstname: form.firstname,
        lastname: form.lastname,
        secondname: form.secondname,
        phone: form.phone,
        host: 'lk.' + window.location.hostname,
        geoByIp: true,
        consent: true,
        bankruptcy: {
            debt: form.debt,
            property: form.property,
        },
        utm: Sender.getUtms(),
    };
};

var send = function(form) {
    return Sender.send(getEndpointUrl(), getRequestPayload(form));
};

export { send }
