var suggestRegion = function(search) {
    var url = process.env.VUE_APP_API_BASE_URL + '/api/geo/suggest/region';
    return fetch(`${url}?q=${search}&count=6`).then(res => res.json());
};

var suggestCity = function(search, region) {
    var url = process.env.VUE_APP_API_BASE_URL + '/api/geo/suggest/city';
    return fetch(`${url}?q=${search}&count=6&region=${region}`).then(res => res.json());
};
var suggestStreet = function(search, region) {
    var url = process.env.VUE_APP_API_BASE_URL + '/api/geo/suggest/street';
    return fetch(`${url}?q=${search}&count=6&region=${region}`).then(res => res.json());
};

var getGeoFromIp = function() {
    var url = process.env.VUE_APP_API_BASE_URL + '/api/geo/byip';
    return fetch(url).then(res => res.json());
};

var suggestFmsCode = function(search) {
    var url = process.env.VUE_APP_API_BASE_URL + '/api/suggest/fmsCode';
    return fetch(`${url}?q=${search}`).then(res => res.json());
};
var suggestFio = function(search, type) {
    var url = process.env.VUE_APP_API_BASE_URL + '/api/suggest/fio';
    return fetch(`${url}?q=${search}&type=${type}`).then(res => res.json());
};
var suggestEmail = function(search) {
    var url = process.env.VUE_APP_API_BASE_URL + '/api/suggest/email';
    return fetch(`${url}?q=${search}`).then(res => res.json());
};
export { suggestRegion, suggestCity, suggestStreet, getGeoFromIp, suggestFmsCode, suggestFio, suggestEmail }
