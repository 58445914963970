<style>
.not-round .q-btn--rounded {
  border-radius: 8px !important;
  background: #c61818 !important;
  border: none !important;
}
.button-wrapper .q-btn {
  border-radius: 10px;
  padding: 8px 30px;
  font-weight: 600;
  font-size: 20px;
  background: #c61818;
  color: #fff;

  -webkit-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}
#loginformChange {
  justify-content: center;
}
.login-form__lower-txt {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.login-page__form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-page .q-field__inner {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.login-page .q-field {
  flex-direction: column;
}
.readable {
  align-items: center;
}
.customColor {
  color: #c10015;
}
.readable .q-field--with-bottom {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
</style>
<style scoped lang="scss">
.readable {
  .q-field__inner:hover::after {
    position: absolute;
    top: -30%;
    left: 70%;
    width: fit-content;
    content: "";
    background-color: white;
    color: #000;
    line-height: 0em;
    border: 0px solid #000;
    box-shadow: 2px 2px 10px #999;
    border-radius: 0%;
    padding: 2px;
  }
}

.col-md-12 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
<style scoped lang="scss">
.login-form__title {
  font-size: 20px;
}
</style>
<template>
  <div class="login-page">
    <div class="container">
      <div class="login-page__center">
        <div v-show="page == 'phone'" class="login-page__form login-form">
          <div class="row" id="loginformTitle">
            <div
              class="login-form__title col fw-m text-center"
              style="font-size: 32px; font-weight: 700"
            >
              Подтвердите номер телефона
            </div>
          </div>
          <div class="row readable flex-column" id="loginformInput">
            <span
              class="customColor"
              style="font-size: 32px; margin-bottom: 5px; font-weight: 700"
              >{{ phone }}
            </span>
            <span
              style="
                font-size: 22px;
                line-height: 1.2;
                margin-bottom: 20px;
                text-align: center;
              "
              >Проверьте правильность введённого номера. <br />
              Если номер введен неверно, нажмите
              <a
                role="button"
                v-on:click.prevent="showNum"
                class="change-number"
                href="#"
                >изменить номер</a
              ></span
            >

            <q-input
              v-model="phone"
              :rules="phoneValidate"
              rounded
              outlined
              placeholder="+7 (___) ___-____"
              mask="+7 (###) ###-####"
              :color="themeColor"
              v-if="num"
            >
              <template v-slot:after>
                <q-btn v-on:click="hideNum" round dense flat label="Готово" />
              </template>
            </q-input>
          </div>
          <div class="error" v-if="error !== null">{{ error }}</div>
          <div class="row" id="loginformButton">
            <div class="col-md-12 d-flex button-wrapper">
              <q-btn
                @click="entry"
                :ripple="{ center: true }"
                rounded
                :color="themeColor"
                label="Получить звонок"
              ></q-btn>
            </div>
          </div>
        </div>

        <div v-show="page == 'code'" class="login-page__form login-form">
          <div class="row" id="loginformTitle">
            <div
              v-if="isShow"
              class="login-form__title col fw-m"
              style="font-size: 32px; font-weight: 700; text-align: center"
            >
              Подтвердите номер телефона
            </div>
          </div>
          <div
            class="row flex-between"
            id="loginformChange"
            style="margin-bottom: 10px"
            v-if="isShow"
          >
            <span
              id="txt"
              class="customColor"
              style="font-size: 32px; margin-bottom: 5px; font-weight: 700"
              >{{ phone }}
            </span>
            <div
              class="login-form__code-label text-center col-12 col-md-10"
              style="font-size: 22px; line-height: 1.2; margin-bottom: 20px"
            >
              <span>Сейчас на Ваш номер телефона поступит звонок.</span>
              <span class="txt-no-wrap text-center" id="loginformNumber">
                Возьмите трубку и введите 4 цифры, которые назовёт робот.
              </span>
            </div>
          </div>
          <div class="row flex-column" id="loginformInput"></div>
          <div class="error" v-if="error !== null && isShow">{{ error }}</div>
          <div class="row" id="loginformLowerText">
            <div
              class="login-form__lower-txt"
              style="margin-bottom: 10px; margin-top: -20px"
            >
              <br />
              <span v-if="timeDifference >= 0 && retry >= 4"
                ><span>{{ timerOutput }}</span></span
              >
              <span v-if="timeDifference >= 0 && retry < 4"
                >Получить новый код можно через
                <span>{{ timerOutput }}</span></span
              >
              <span>
                <a
                  class="txt-primary"
                  href="#"
                  role="button"
                  @click="entry"
                  v-if="timeDifference <= 0 && retry < 4"
                  >Позвонить еще раз</a
                >
                <span v-if="retry < 4"> (осталось {{ 4 - retry }})</span>
              </span>
              <a
                v-if="retry < 4"
                role="button"
                v-on:click.prevent="readable"
                class="change-number"
                href="#"
                >Изменить номер</a
              >
              <a
                role="button"
                v-if="retry >= 4 && isShow && timeDifference < 0"
                v-on:click.prevent="changingText"
                href="#"
                >Не поступает звонок?</a
              >
            </div>
          </div>
          <div
            class="col-md-12 d-flex button-wrapper"
            style="justify-content: center"
          >
            <q-btn
              @click="confirm"
              :ripple="{ center: true }"
              rounded
              :color="themeColor"
              label="Подтвердить"
              v-if="retry <= 4 && isShow"
            ></q-btn>
          </div>
        </div>
      </div>
      <div
        v-if="isHide"
        class="login-form__code-label text-center col-12"
        style="font-size: 22px"
      >
        <span>Посмотрите предложения наших партнёров.</span>
        <span class="txt-no-wrap text-center" id="loginformNumber">
          Доступны суммы до <b class="customColor">30 000 ₽</b> на срок до
          <b class="customColor">90 дн.</b> под
          <b class="customColor">0%</b>
        </span>
        <div class="col-md-12 d-flex" style="justify-content: center">
          <q-btn
            @click="partner"
            :ripple="{ center: true }"
            rounded
            style="
              background: #0038a5;
              border: 3px solid #0038a5;
              color: white;
              margin-top: 20px;
              margin-bottom: 20px;
            "
            label="Посмотреть"
          ></q-btn>
        </div>
      </div>
      <span v-if="isHide" style="font-size: 16px"
        >Если Вы не получили звонок, попробуйте сделать это: <br /><br />
        1. Проверьте историю звонков. Возможно, Вы случайно сбросили звонок<br />
        2. Проверьте, что Ваш номер телефона находится в зоне действия сети
        оператора<br />
        3. Перезагрузите телефон<br />
        4. Если на Вашем номере телефона установлена услуга "СтопСпам", то
        скорее всего к Вам не дойдет звонок. Рекомендуем Вам временно отключить
        эту услугу для получения кода. Если это невозможно, перезаполните анкету
        с номером телефона другого оператора.<br />
        5. Попробуйте перезаполнить анкету через 20-25 минут.
      </span>
      <div class="col-md-12 d-flex not-round" style="justify-content: center">
        <q-btn
          @click="mainPage"
          :ripple="{ center: true }"
          rounded
          style="
            background: #0038a5;
            border: 3px solid #0038a5;
            color: white;
            margin-top: 20px;
            margin-bottom: 20px;
          "
          label="На главную"
          v-if="isHide"
        ></q-btn>
      </div>
    </div>
  </div>
</template>

<script>
import * as Validate from "@/assets/js/validators.js";
import * as ConfirmTel from "@/assets/js/confirm_tel.js";
import * as DataParams from "@/assets/js/data_params.js";
import { ninvoke } from "q";

export default {
  name: "Confirm",

  props: {
    formType: String,
    forwardPage: Function,
    backwardPage: Function,
    previousPagePromise: Promise,
  },

  data: () => ({
    themeColor: DataParams.get("color"),
    phone: "",
    code: "",
    isShow: true,
    num: false,
    isHide: false,
    phoneValid: true,
    error: null,
    page: "phone",
    isReadonly: true,
    countDownToTime: 0,
    timeDifference: 0,
    timerOutput: null,
    interval: null,
    retry: 0,
  }),

  computed: {
    phoneValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите телефон",
        (val) => Validate.phone(val) || "Введите полностью",
      ];
    },
  },
  watch: {
    timeDifference(val) {
      if (val < 2) {
        clearInterval(this.interval);
      }
    },
    phone(val) {
      if (val) {
        this.retry = 0;
      }
    },
  },
  methods: {
    mainPage() {
      window.open("https://zzaym.su");
    },
    partner() {
      window.open("http://1fin.su/2ZgLB");
      window.open("http://1fin.su/Aj7R2");
    },
    changingText() {
      this.isShow = false;
      this.isHide = true;
    },
    showNum() {
      this.num = true;
    },
    hideNum() {
      this.num = false;
    },
    readable() {
      this.isReadonly = false;
      this.page = "phone";
    },

    startTimer() {
      this.countDownToTime = new Date().getTime() + 5000;
      this.interval = setInterval(() => {
        const timeNow = new Date().getTime();
        this.timeDifference = this.countDownToTime - timeNow;
        const millisecondsInOneSecond = 1000;
        const millisecondsInOneMinute = millisecondsInOneSecond * 60;
        const millisecondsInOneHour = millisecondsInOneMinute * 60;
        const millisecondsInOneDay = millisecondsInOneHour * 24;
        const differenceInDays = this.timeDifference / millisecondsInOneDay;
        const remainderDifferenceInHours =
          (this.timeDifference % millisecondsInOneDay) / millisecondsInOneHour;
        const remainderDifferenceInMinutes =
          (this.timeDifference % millisecondsInOneHour) /
          millisecondsInOneMinute;
        const remainderDifferenceInSeconds =
          (this.timeDifference % millisecondsInOneMinute) /
          millisecondsInOneSecond;
        const remainingDays = Math.floor(differenceInDays);
        const remainingHours = Math.floor(remainderDifferenceInHours);
        const remainingMinutes = Math.floor(remainderDifferenceInMinutes);
        const remainingSeconds = Math.floor(remainderDifferenceInSeconds);
        this.timerOutput =
          (remainingMinutes < 10 ? "0" + remainingMinutes : remainingMinutes) +
          ":" +
          (remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds);
      }, 1000);
    },

    async entry() {
      if (!this.phoneValidate) {
        return;
      }

      this.startTimer();
      this.retry++;
      console.log(this.retry);
      const resp = await ConfirmTel.entry(this.phone);
      if (!resp.error) {
        this.page = "code";
        
      }
    },

    async confirm() {
      const response = await ConfirmTel.confirm(this.code);
      this.error = response.error;
      if (response.error === null) {
        this.forwardPage();
      }
    },

   
  },

  mounted() {
    this.phone = window.phone;
  },
};
</script>
