<style scoped>
    .blue {
        color: #0062d4;
    }
    .bg-image {
        max-width: 150px;
        max-height: 150px;
    }
</style>

<template>
    <div class="row">
        <form class="col-md-12" novalidate @submit="submit">

            <div class="pt-3 pb-4">Одобрение займа в течение <span class="blue">5 минут</span></div>

            <div class="row">
                <div class="col-md-4 pb-4">
                    <label for="anketa-firstname">Имя</label>
                    <input type="text" class="form-control" id="anketa-firstname" :class="{'is-invalid': !valid.firstname}" v-model="form.firstname" @change="validate" autocomplete="off" placeholder="Имя">
                </div>
                <div class="col-md-4 pb-4">
                    <label for="anketa-phone">Телефон</label>
                    <input type="text" class="form-control" id="anketa-phone" v-mask="{mask: '+7 (999) 999-9999'}" :class="{'is-invalid': !valid.phone}" v-model="form.phone" @change="validate" autocomplete="off" placeholder="+7 (___) ___-____">
                </div>
                <div class="col-md-4 text-center d-none d-sm-none d-md-block" style="height:0px;">
                    <img class="bg-image" :src="origin() + require('../assets/img/pts_bg.jpg')">
                </div>
            </div>

            <div class="row">
                <div class="col-md-4 pb-4">
                    <label for="anketa-amount">Сумма займа (рубли)</label>
                    <input type="text" class="form-control" id="anketa-amount" v-mask="{regex: '\\d*'}" :class="{'is-invalid': !valid.amount}" v-model="form.amount" @change="validate" autocomplete="off">
                    <div class="txt-gray">от 30 000 ₽ до 5 000 000 ₽</div>
                    <div id="sliderSummaTop" class="calculator__slider slider"></div>
                </div>
                <div class="col-md-4 pb-4">
                    <label for="anketa-period">Срок займа (месяцы)</label>
                    <input type="text" class="form-control" id="anketa-period" v-mask="{regex: '\\d*'}" :class="{'is-invalid': !valid.period}" v-model="form.period" @change="validate" autocomplete="off">
                    <div class="txt-gray">от 3 до 60 месяцев</div>
                    <div id="sliderSrokTop" class="calculator__slider slider"></div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4 pb-4">
                    <label for="anketa-carBrand">Марка автомобиля</label>
                    <v-select
                        class="form-control"
                        :class="{'is-invalid': !valid.carBrand}"
                        v-model="form.carBrand"
                        :options="Object.keys(carLevel1List)"
                        @option:selected="chooseCarLevel1"
                        id="anketa-carBrand"
                    >
                        <template v-slot:no-options="{ search, searching }">Не найдено</template>
                    </v-select>
                </div>
                <div class="col-md-4 pb-4">
                    <label for="anketa-carModel">Модель</label>
                    <v-select
                        class="form-control"
                        :class="{'is-invalid': !valid.carModel}"
                        v-model="form.carModel"
                        :options="carLevel2List"
                        :reset-on-options-change="true"
                        @option:selected="validate"
                        id="anketa-carModel"
                    >
                        <template v-slot:no-options="{ search, searching }">Не найдено</template>
                    </v-select>
                </div>
                <div class="col-md-4 pb-4">
                    <label for="anketa-carYear">Год выпуска</label>
                    <input type="text" class="form-control" id="anketa-carYear" v-mask="{mask: '9999'}" :class="{'is-invalid': !valid.carYear}" v-model="form.carYear" @change="validate" autocomplete="off" placeholder="2014">
                </div>
                
                
            </div>

            <div class="row">
                <div class="col-md-4 pb-4">
                    <button class="btn btn-primary form-control" @click="next">Отправить заявку</button>
                </div>
                <div class="col-md-8 pb-4 text-secondary d-flex align-items-center">
                    <small>Нажимая на кнопку, вы соглашаетесь с <a href="/docs/Politika_obrabotki_personalnyh_dannyh.docx">политикой</a> обработки персональных данных, даете
                        <a href="/docs/Soglasie_na_obrabotku_personalnyh_dannyh.docx">согласие</a> на обработку персональных данных и на <a href="/docs/Soglasie_na_poluchenie_reklamnyh_materialov.docx">получение</a> рекламных материалов</small>
                </div>
            </div>

        </form>
    </div>
</template>

<script>
import vSelect from '@/../node_modules/vue-select/src/index.js';
import * as Bid from '@/assets/js/bid/pts_create.js'
import * as Validate from '@/assets/js/validators.js'
import * as GetParams from '@/assets/js/get_params.js'
import * as DataParams from '@/assets/js/data_params.js'
import * as Goal from '@/assets/js/goal.js'

export default {
    name: 'FormPts',
    components: { vSelect },

    props: {
        formType: String,
        forwardPage: Function,
        backwardPage: Function,
        previousPagePromise: Promise
    },

    data: () => ({
        carLevel1List: [],
        carLevel2List: [],
        isNextClicked: false,
        sendPromise: Promise.resolve(),
        form: {
            firstname: null,
            phone: null,
            amount: GetParams.get('amount'),
            period: GetParams.get('period'),
            carBrand: null,
            carModel: null,
            carYear: null,
        },
        valid: {
            firstname: true,
            phone: true,
            amount: true,
            period: true,
            carBrand: true,
            carModel: true,
            carYear: true,
        },
    }),

    methods: {
        chooseCarLevel1(brand) {
            this.carLevel2List = this.carLevel1List[brand];
            this.validate();
        },

        origin() {
            return DataParams.getOriginUrl();
        },

        //Нажатие на кнопку "продолжить"
        next(e) {
            this.isNextClicked = true;
            this.validate() || e.preventDefault();
        },

        //Валидация формы
        validate() {
            //Не валидируем, пока не была нажата кнопка далее
            if (this.isNextClicked === false) {
                return true;
            }

            this.valid.firstname = Validate.noempty(this.form.firstname);
            this.valid.phone = Validate.phone(this.form.phone);
            this.valid.amount = Validate.noempty(this.form.amount);
            this.valid.period = Validate.noempty(this.form.period);
            this.valid.carBrand = Validate.noempty(this.form.carBrand);
            this.valid.carModel = Validate.noempty(this.form.carModel);
            this.valid.carYear = Validate.year(this.form.carYear);

            for (var field in this.valid) {
                if (this.valid[field] === false) {
                    return false;
                }
            }

            return true;
        },

        //Отправка формы
        submit(e) {
            e.preventDefault();
            Goal.newBid();
            this.sendPromise = this.sendPromise.then(() => Bid.send(this.form));
            this.forwardPage(this.sendPromise);
        }
    },

    mounted () {
        //Загрузка моделей автомобилей
        fetch(DataParams.getOriginUrl() + '/js/cars.json', {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        }).then(response => {
            return response.json();
        }).then(response => {
            this.carLevel1List = response.list;
        });
    }
}
</script>
