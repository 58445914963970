<style scoped>
    .wrapper {
        min-height: 300px;
        justify-content: center;
        align-items: center;
    }
    
</style>

<template>
    <div class="row wrapper">
        <div class="form-success" id="form-success">
        <div class="form-success-bg"> </div>
        <div class="form-success-title">Ваша заявка отправлена</div>
        <div class="form-success-subtitle">Менеджер свяжется с вами через 2-3 минуты</div>
    </div> </div><div></div><div></div><div>
    </div>
</template>

<script>

export default {
    name: 'Redirect',

    props: {
        formType: String,
        forwardPage: Function,
        backwardPage: Function,
        previousPagePromise: Promise,
    },

    mounted () {
        
    }
}
</script>
