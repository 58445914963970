<style scoped>
.bg-green {
  background: linear-gradient(
    90deg,
    rgba(17, 147, 167, 1) 0%,
    rgba(49, 187, 56, 1) 100%
  ) !important;
}
@media (max-width: 500px) {
  .data-text {
    align-items: center;
  
  }
  .data-text-wrapper{
    padding: 10px 0px;
  }
}
</style>
<style  lang="scss">

img {
  max-width: 100%;
}
@media (max-width: 500px) {


  .q-btn--rounded {
    .block {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}
@media (max-width: 400px) {
  .btn-mts{
    padding: 12px 20px;
  }
}
</style>

<template>
  <form>
    <div class="data-text-top data-text">
      <div class="data-text-wrapper">
        <div class="data-sum">
          <div class="data-title">Сумма:</div>
          <div class="data-subtitle">{{ this.formatSum }} рублей</div>
        </div>
        <div class="data-period">
          <div class="data-title">Срок:</div>
          <div class="data-subtitle custom-ml">{{ period }} дней</div>
        </div>
      </div>
    </div>
   
    <div class="col steps-bg">
      <div class="step-wrapper">
        <div class="step-wrapper-percent">
          <div class="">
            <div>
              <img class="" :src="require('../assets/img/1st-step.png')" />
            </div>
          </div>
          <div class="percentage-1">
            <div class="percentage-title">
              Одобрение
              <div>+40%</div>
            </div>
          </div>
        </div>

        <div class="first-line-wrapper">
          <img
            class="first-line"
            :src="require('../assets/img/just-line.png')"
          />
        </div>

        <div class="step-wrapper-percent">
          <div class="">
            <div>
              <img class="" :src="require('../assets/img/2nd-step-just.png')" />
            </div>
          </div>
          <div class="percentage-1">
            <div class="percentage-title">
              Одобрение
              <div>+35%</div>
            </div>
          </div>
        </div>
        <div class="first-line-wrapper">
          <img
            class="first-line"
            :src="require('../assets/img/2nd-line.png')"
          />
        </div>
        <div class="step-wrapper-percent">
          <div class="">
            <div>
              <img class="" :src="require('../assets/img/3rd-step.png')" />
            </div>
          </div>
          <div class="percentage-1">
            <div class="percentage-title">
              Одобрение
              <div>+25%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="step-title">
      <div class="step-title-1">Шаг 2. Личные данные</div>
    </div>
    <div class="first-step-wrapper">
      <div class="wrapper first-step">
        <div class="input-wrap">
          <q-select
            :color="themeColor"
            :rules="lastnameValidate"
            v-model="form.lastname"
            use-input
            rounded
            outlined
            hide-selected
            @input-value="(val) => (form.lastname = val)"
            fill-input
            input-debounce="0"
            label="Фамилия"
            placeholder="Иванов"
            class="input-req"
            :options="lastnameSuggestOptions"
            @filter="fetchLastname"
            ref="inputLastname"
            new-value-mode="add"
            behavior="menu"
          >
          </q-select>
        </div>
        <div class="input-wrap">
          <q-select
            :color="themeColor"
            :rules="firstnameValidate"
            v-model="form.firstname"
            use-input
            rounded
            outlined
            hide-selected
            fill-input
            class="input-req"
            @input-value="(val) => (form.firstname = val)"
            input-debounce="0"
            label="Имя"
            :options="firstnameSuggestOptions"
            @filter="fetchFirstname"
            new-value-mode="add"
            placeholder="Иван"
            behavior="menu"
            ref="inputFirstname"
          >
          </q-select>
        </div>
      </div>
      <div class="wrapper first-step">
        <div class="input-wrap">
          <q-select
            :color="themeColor"
            :rules="secondnameValidate"
            v-model="form.secondname"
            use-input
            rounded
            outlined
            hide-selected
            class="input-req"
            fill-input
            @input-value="(val) => (form.secondname = val)"
            input-debounce="0"
            label="Отчество"
            :options="secondnameSuggestOptions"
            @filter="fetchSecondname"
            new-value-mode="add"
            placeholder="Иванович"
            behavior="menu"
            ref="inputSecondname"
          >
          </q-select>
        </div>
        <div class="input-wrap">
          <q-input
            :color="themeColor"
            :rules="dobValidate"
            rounded
            outlined
            class="input-req"
            v-model="form.dob"
            label="Дата рождения"
            mask="##.##.####"
            placeholder="25.01.1995"
            ref="inputDob"
          />
        </div>
      </div>
      <div class="wrapper first-step">
        <div class="input-wrap">
          <q-select
            :color="themeColor"
            :rules="regionValidate"
            rounded
            outlined
            v-model="regionTmp"
            class="input-req"
            use-input
            hide-selected
            fill-input
            @input-value="(val) => (form.region = val)"
            input-debounce="0"
            label="Регион"
            :options="regionSuggestOptions"
            @filter="fetchRegionSuggest"
            new-value-mode="add"
            placeholder="Красноярский край"
            behavior="menu"
            ref="inputRegion"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey"> Введите </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div>
        <div class="input-wrap">
          <q-select
            :color="themeColor"
            :rules="cityValidate"
            rounded
            outlined
            v-model="cityTmp"
            class="input-req"
            use-input
            hide-selected
            fill-input
            @input-value="(val) => (form.city = val)"
            input-debounce="0"
            label="Город"
            :options="citySuggestOptions"
            @filter="fetchCitySuggest"
            new-value-mode="add"
            placeholder="Красноярск"
            behavior="menu"
            ref="inputCity"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey"> Введите </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div>
      </div>
      <div class="confirm-button-wrapper">
        <div class="confirm-button">
          <q-btn
            @click="secondStepConfirmHandler()"
            :ripple="{ center: true }"
            rounded
            :color="themeColor"
            label="Продолжить"
            class="confirm-button-next"
          />
        </div>
        <div class="data-text-lower data-text">
          <div class="data-text-wrapper">
            <div class="data-sum">
              <div class="data-title">Сумма:</div>
              <div class="data-subtitle">{{ this.formatSum }} рублей</div>
            </div>
            <div class="data-period">
              <div class="data-title">Срок:</div>
              <div class="data-subtitle custom-ml">{{ period }} дней</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { debounce } from "debounce";
import vSelect from "@/../node_modules/vue-select/src/index.js";
import * as Bid from "@/assets/js/bid/default_create.js";
import * as ConfirmTel from "@/assets/js/confirm_tel.js";
import * as Suggest from "@/assets/js/suggest.js";
import * as Validate from "@/assets/js/validators.js";
import * as GetParams from "@/assets/js/get_params.js";
import * as DataParams from "@/assets/js/data_params.js";
import * as Goal from "@/assets/js/goal.js";

export default {
  name: "FormDefaultNewStepTwoWithParams",

  components: { vSelect },

  props: {
    formType: String,
    forwardPage: Function,
    backwardPage: Function,
    previousPagePromise: Promise,
  },

  data: () => ({
    phone: "",
    code: "",
    isShow: true,
    num: false,
    isHide: false,
    phoneValid: true,
    error: null,
    page: "phone",
    isReadonly: true,
    countDownToTime: 0,
    timeDifference: 0,
    timerOutput: null,
    interval: null,
    retry: 0,

    docs: false,
    num: false,
    phoneConfirm: false,
    showRepeatBtn: false,
    showCode: true,
    themeColor: DataParams.get("color"),
    isNextClicked: false,
    isPartialSubmitted: false,
    sendPromise: Promise.resolve(),
    firstnameSuggestOptions: [],
    lastnameSuggestOptions: [],
    secondnameSuggestOptions: [],
    regionSuggestOptions: [],
    citySuggestOptions: [],
    regionTmp: null,
    cityTmp: null,
    form: {
      firstname: null,
      lastname: null,
      secondname: null,
      dob: null,
      region: null,
      city: null,
      cityKladr: null,
      regionKladr: null,
      amount: GetParams.get("amount"),
      period: GetParams.get("period"),
    },
    valid: {
      lastname: true,
      firstname: true,
      secondname: true,
      dob: true,
      region: true,
      city: true,
    },
  }),

  computed: {
    //validation

    lastnameValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите фамилию",
        (val) => Validate.textOnly(val) || "Введите корректно",
      ];
    },
    firstnameValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите имя",
        (val) => Validate.textOnly(val) || "Введите корректно",
      ];
    },
    secondnameValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите отчество",
        (val) => Validate.textOnly(val) || "Введите корректно",
      ];
    },
    dobValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите дату рождения",
        (val) => Validate.validate18Years(val) || "Введите верную дату",
      ];
    },
    cityValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите город проживания",
        (val) => Validate.noempty(val) || "Введите полностью",
      ];
    },
    regionValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите регион проживания",
        (val) => Validate.noempty(val) || "Введите полностью",
      ];
    },
    formatSum() {
      return this.$store.state.anketa.amount.toLocaleString("ru-RU");
    },
    period() {
      return this.$store.state.anketa.period;
    },
  },

  watch: {
    regionTmp: function (val) {
      this.form.region = val?.value;
      this.form.regionKladr = val?.id;
    },
    cityTmp: function (val) {
      this.form.city = val?.value;
      this.form.cityKladr = val?.id;
    },
  },
  methods: {
    async secondStepConfirmHandler() {
      const isValidLastname = await this.$refs.inputLastname.validate();
      const isValidFirstname = await this.$refs.inputFirstname.validate();
      const isValidSecondname = await this.$refs.inputSecondname.validate();
      const isValidDob = await this.$refs.inputDob.validate();
      const isValidRegion = await this.$refs.inputRegion.validate();
      const isValidCity = await this.$refs.inputCity.validate();
      if (
        !isValidLastname ||
        !isValidFirstname ||
        !isValidSecondname ||
        !isValidDob ||
        !isValidRegion ||
        !isValidCity
      ) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
      const resp = Bid.send(this.form);
      
      this.forwardPage(this.sendPromise);
      this.isNextClicked = true;
    },

    //suggestions
    //fio
    fetchLastname: debounce(function (search, update) {
      if (!search.length) {
        () => (this.lastnameSuggestOptions = [search]);
        return;
      }
      this.fetchFioSuggest(search, update, "surname");
    }, 500),

    fetchFirstname: debounce(function (search, update) {
      if (!search.length) {
        () => (this.firstnameSuggestOptions = [search]);
        return;
      }
      this.fetchFioSuggest(search, update, "name");
    }, 500),

    fetchSecondname: debounce(function (search, update) {
      if (!search.length) {
        () => (this.secondnameSuggestOptions = [search]);
        return;
      }
      this.fetchFioSuggest(search, update, "patronymic");
    }, 500),

    fetchFioSuggest(search, update, type) {
      Suggest.suggestFio(search, type)
        .then((data) => {
          update(() => {
            if (type === "name") {
              this.firstnameSuggestOptions = data.map((el) => el.text);
            }
            if (type === "surname") {
              this.lastnameSuggestOptions = data.map((el) => el.text);
            }
            if (type === "patronymic") {
              this.secondnameSuggestOptions = data.map((el) => el.text);
            }
          });
        })
        .catch(() => {
          update(() => {
            if (type === "name") {
              this.firstnameSuggestOptions = [search];
            }
            if (type === "surname") {
              this.lastnameSuggestOptions = [search];
            }
            if (type === "patronymic") {
              this.secondnameSuggestOptions = [search];
            }
          });
        });
    },

    //region
    fetchRegionSuggest: debounce(function (search, update) {
      if (search.length < 2) {
        () => (this.regionSuggestOptions = []);
        return;
      }
      Suggest.suggestRegion(search)
        .then((data) => {
          update(() => {
            if (data.length)
              this.regionSuggestOptions = data.map((el) => ({
                value: el.text,
                label: el.text,
                id: el.id,
              }));
            else
              this.regionSuggestOptions = [
                { value: search, label: search, id: null },
              ];
          });
        })
        .catch(() => {
          update(() => {
            this.regionSuggestOptions = [
              { value: search, label: search, id: null },
            ];
          });
        });
    }, 500),

    //city
    fetchCitySuggest: debounce(function (search, update) {
      if (search.length < 2) {
        () => (this.citySuggestOptions = []);
        return;
      }
      Suggest.suggestCity(search, this.form.regionKladr)
        .then((data) => {
          update(() => {
            if (data.length)
              this.citySuggestOptions = data.map((el) => ({
                value: el.text,
                label: el.text,
                id: el.id,
              }));
            else
              this.citySuggestOptions = [
                { value: search, label: search, id: null },
              ];
          });
        })
        .catch(() => {
          update(() => {
            this.citySuggestOptions = [
              { value: search, label: search, id: null },
            ];
          });
        });
    }, 500),
  },

  mounted() {
    //Подстановка geo по ip
    Suggest.getGeoFromIp().then((geo) => {
      if (geo.cityKladr === null) {
        return;
      }
      this.regionTmp = {
        value: geo.region,
        label: geo.region,
        id: geo.regionKladr,
      };
      this.cityTmp = { value: geo.city, label: geo.city, id: geo.cityKladr };
      this.regionSuggestOptions = [this.regionTmp];
      this.citySuggestOptions = [this.cityTmp];
    });
  },
};
</script>
