<style scoped>
    .radios {
        display: flex;
        align-items: center;
        height: calc(1.5em + 0.75rem + 2px);
    }

    .button-wrapper {
        justify-content: flex-end;
    }

    @media (max-width: 767px) {
        .button-wrapper {
            justify-content: center;
        }
    }
</style>

<template>
    <div class="row">
        <form class="col-md-12" novalidate @submit="submit">
            <h3 class="text-center pt-3 pb-2">Самые быстрые займы под 0%</h3>
            <h5 class="text-left pt-3 pb-2 first-step_txt"><span>Контактная информация</span> <span>Шаг 1 из 2</span></h5>
            <div class="row">
                <div class="col-md-6 pb-2">
                    <label for="anketa-phone">Телефон</label>
                    <input type="text" class="form-control" id="anketa-phone" v-mask="{mask: '+7 (999) 999-9999'}" :class="{'is-invalid': !valid.phone}" v-model="form.phone" @change="validate(); sendPartial();" autocomplete="off" placeholder="+7 (___) ___-____">
                </div>
                <div class="col-md-6 pb-2">
                    <label for="anketa-email">Email</label>
                    <input type="email" class="form-control" id="anketa-email" :class="{'is-invalid': !valid.email}" v-model="form.email" @change="validate(); sendPartial();" autocomplete="off">
                </div>
            </div>

            <h4 class="text-left pt-3">Параметры займа</h4>
            <div class="row mb-2 pb-2">
                <div class="col-md-6">
                    <label for="anketa-amount">Сумма займа (рубли)</label>
                    <input type="text" class="form-control" id="anketa-amount" v-mask="{regex: '\\d*'}" :class="{'is-invalid': !valid.amount}" v-model="form.amount" @change="validate" autocomplete="off">
                </div>
                <div class="col-md-6 pb-2">
                    <label for="anketa-period">Срок займа (дни)</label>
                    <input type="text" class="form-control" id="anketa-period" v-mask="{regex: '\\d*'}" :class="{'is-invalid': !valid.period}" v-model="form.period" @change="validate" autocomplete="off">
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 pb-2">
                    <label for="anketa-creditHistory">Кредитная история</label>
                    <select class="custom-select d-block w-100" id="anketa-creditHistory" v-model="form.creditHistory">
                        <option v-for="item in creditHistoryItems" :value="item.id">{{ item.value }}</option>
                    </select>
                </div>
            </div>

            <div class="row" style="padding-top:2em;">
                <div class="col-md-12 pb-2">
                    <div class="custom-control custom-checkbox anketa-control-consent">
                        <input type="checkbox" class="custom-control-input" id="anketa-consent" :class="{'is-invalid': !valid.consent}" v-model="form.consent" @change="validate" autocomplete="off">
                        <label class="custom-control-label" for="anketa-consent">
                            <small style="vertical-align: text-top;" v-html="getConsentText()"></small>
                        </label>
                    </div>
                </div>
            </div>

            <div class="row pt-3 pb-4">
                <div class="col-md-12 d-flex button-wrapper">
                    <button class="btn btn-primary btn-lg btn-block" style="width: 200px;" @click="next">Продолжить</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { debounce } from 'debounce';
import vSelect from '@/../node_modules/vue-select/src/index.js';
import * as Bid from '@/assets/js/bid/default_create.js';
import * as Validate from '@/assets/js/validators.js';
import * as GetParams from '@/assets/js/get_params.js';
import * as Goal from '@/assets/js/goal.js';

export default {
    name: 'FormDefaultStepOne',
    components: { vSelect },

    props: {
        formType: String,
        forwardPage: Function,
        backwardPage: Function,
        previousPagePromise: Promise,
    },

    data: () => ({
        consentTextDefault: 'Даю свое согласие на <a href="/docs/Soglasie_na_obrabotku_personalnyh_dannyh.pdf" target="_blank">обработку персональных данных</a>, на <a href="/docs/Soglasie_na_poluchenie_reklamnyh_materialov.pdf" target="_blank">получение</a> рекламных материалов. Соглашаюсь с условиями <a href="/docs/Oferta.pdf" target="_blank">оферты</a>. Я принял соглашение на оформление <a href="/docs/Prilozhenie_4_Soglashenie_na_oformlenie_platnoj_podpiski.pdf" target="_blank">платной подписки</a>. Для активации сервиса будет списан 1 рубль, дальнейшее использование сервиса будет проходит в соответствии с <a href="/docs/Prilozhenie_2_Informacija_o_dejstvujushhih_tarifah.pdf" target="_blank">тарифами сервиса</a>. Стоимость услуги четыреста девяносто восемь рублей каждые 5 дней по подписке. Подписка не гарантирует получение займа!',
        isNextClicked: false,
        isPartialSubmitted: false,
        sendPromise: Promise.resolve(),
        regionSuggestOptions: [],
        citySuggestOptions: [],
        form: {
            phone: null,
            email: null,
            amount: GetParams.get('amount'),
            period: GetParams.get('period'),
            creditHistory: 0,
            consent: false
        },
        valid: {
            phone: true,
            email: true,
            amount: true,
            period: true,
            creditHistory: true,
            consent: true
        },
        creditHistoryItems: [
            {id: 0, value: 'Нет кредитной истории'},
            {id: 1, value: 'Был займ, просрочек не было'},
            {id: 2, value: 'Был займ, просрочки были'},
            {id: 3, value: 'Открытый займ и просрочки'},
            {id: 4, value: 'Работают коллекторы'}
        ],
    }),

    methods: {
        getConsentText() {
            return window.consent || this.consentTextDefault;
        },

        //Нажатие на кнопку "продолжить"
        next(e) {
            this.isNextClicked = true;
            if(!this.validate()) {
                e.preventDefault();
            }
        },

        //Валидация формы
        validate() {
            //Не валидируем, пока не была нажата кнопка далее
            if (this.isNextClicked === false) {
                return true;
            }

            this.valid.phone = Validate.phone(this.form.phone);
            this.valid.email = Validate.email(this.form.email);
            this.valid.amount = Validate.noempty(this.form.amount);
            this.valid.period = Validate.noempty(this.form.period);
            this.valid.consent = this.form.consent;

            for (var field in this.valid) {
                if (this.valid[field] === false) {
                    return false;
                }
            }

            return true;
        },

        //Отправка данных на сервер, когда были введены валидные телефон и email
        sendPartial() {
            //Если данные уже отправлены
            if (this.isPartialSubmitted === true) {
                return true;
            }

            if (!Validate.phone(this.form.phone) || !Validate.email(this.form.email)) {
                return;
            }

            this.isPartialSubmitted = true;
            this.sendPromise = Bid.send(this.form);
        },

        //Отправка формы
        submit(e) {
            e.preventDefault();
            Goal.newBid();
            this.sendPromise = this.sendPromise.then(() => Bid.send(this.form));
            this.forwardPage(this.sendPromise);
        },
    },

}
</script>
