<template>
    <div class="payment-form-header">
        Уважаемый {{ firstname }} {{ secondname }}! Мы проверили ваши данные. Для активации анкеты вам необходимо ввести данные Вашей банковской карты. Карта должна быть действительной.
    </div>

    <iframe height="440" width="100%" scrolling="no" :src="formUrl" style="border:none;"></iframe>

    <div class="payment-form-footer">
        <div class="payment-form-small">Обращаем ваше внимание, что все вносимые данные хранятся в зашифрованном виде и могут передаваться только в согласии с правилами обработки персональных данных. Ваши данные надежно защищены от злоумышленников. Сервис оказывает платные услуги в согласии с договором публичной оферты, а также в соответствии с законодательством Российской Федерации. Пользуясь сервисом вы соглашаетесь с политикой обработки персональных данных и публичной офертой. Если вы не согласны с политикой сайта — не производите активацию сервиса. Сервис не дает гарантии получения займа. Стоимость услуг составляет 499 (четыреста девяносто девять) рублей. Оплата может взиматься как в момент заполнения заявки, так и отложенным платежом. Возможна дополнительная комиссия за банковский перевод. На сайте действует подписка, условия подписки смотрите в договоре публичной оферты. Для отключения подписки удалите карты в личном кабинете сайта.</div>
    </div>
</template>

<script>
import * as Subscription from '@/assets/js/bid/subscription_iframe.js'

export default {
    name: 'SubscriptionImpaya',

    props: {
        renderForm: Function,
        renderError: Function
    },

    data: () => ({
        formUrl: null,
        firstname: '',
        secondname: ''
    }),

    mounted () {
        Subscription.init().then(data => {
            if (data.error) {
                this.renderError(data.error);
            } else {
                this.firstname = window.bid.firstname;
                this.secondname = window.bid.secondname;
                this.formUrl = data.redirect;
                this.renderForm();
            }
        });

        //redirect
        window.addEventListener('message', e => {
            if(e.data.type !== 'AFTER_PAY') {
                return;
            }

            var url = e.data.data.RedirectUrl;
            var urlParts = url.split('?', 2);
            var redirectUrl = urlParts[0] + '?' + (urlParts[1] || '');

            window.location.replace(redirectUrl);
        });
    }
}
</script>
