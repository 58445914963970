/*
 * Модуль для работы с iframe платежками
 */

var getEndpointUrl = function() {
    return process.env.VUE_APP_API_BASE_URL + '/api/bid/subscription/' + window.bid.paymentSystem + '?id=' + window.bid.id;
};

var init = function() {
    return fetch(getEndpointUrl(), {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    }).then(response => {
        return response.json();
    }).then(data => {
        return data;
    });
};

export { init }
