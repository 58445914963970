<style>
    .q-radio__label{
        color: black;
    }
    #radio-text{
        color: #C10015;
        font-size: 12px;
    }
    .custom-control-label,
    .q-item__label {
        color: black;
    }
    .right-margin h6 {
        color: black;
    }
    .no-percentage {
        margin-left: -8px;
    }
    /* стандартные стили */
    #anketa-app {
       
        
    }
    .wrapper {
        display: flex;
        justify-content: space-between;
        
    }
    .input-wrap {
        display: flex;
    }
</style>
<style>
    .text-caption {
        margin-top: -10px;
        margin-left: -20px;
    }
    body.desktop .q-checkbox:not(.disabled) .q-checkbox__inner:before {
        opacity: 0 !important;
    }
    .q-select__dropdown-icon {
        display: none !important;
    }
    [type=number]::-webkit-inner-spin-button,
    [type=number]::-webkit-outer-spin-button {
        display: none !important;
    }
    .filler {
        width: 100%;
        height: 460px;
    }
    .q-gutter-x-md,
    .q-gutter-md {
        margin-left: 0px !important;
    }
    .q-gutter-x-md>*,
    .q-gutter-md>* {
        margin-left: 0px !important;
    }
    .q-field--square .q-field__control {
        border-radius: 4px 4px 0 0 !important;
    }
    .q-field__inner {
       
    }
    .q-field--outlined.q-field--rounded .q-field__control {
        border-radius: 20px !important;
    }
    .q-field--outlined .q-field__control:hover:before {
        border-color: currentColor !important;
    }
    .custom-radio .custom-control-input:checked~.custom-control-label::after {
        background-image: none !important;
    }
    .custom-control-input:focus~.custom-control-label::before {
        box-shadow: none !important;
    }
    .radios {
        display: flex;
    }
    .sex-padding {
        padding-left: 20px;
    }
    .redstar {
        position: relative;
        right: 4%;
        top: -5%;
    }
    .q-item__label {
        font-size: 1.5rem;
        font-weight: 500;
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .q-expansion-item {
        border-bottom: 1px solid #cccccc;
    }
    .q-expansion-item--standard.q-expansion-item--expanded>div>.q-expansion-item__border {
        opacity: 0 !important;
    }
    /* progressbar */
    .progress-bar {
        border-radius: 20px;
    }
    .wrap-percent {
        display: flex;
        flex-direction: column;
    }
    
</style>
<style lang="scss">
    .nobackground {
        .q-focus-helper {
            background: none !important;
        }
    }
    .progress {
        position: relative;
        bottom: 50px;
        height: 48px !important;
        border-radius: 20px !important;
    }
    .progress-txt {
        display: flex;
        justify-content: center;
        text-align: center;
        top: 15%;
    }
    @media (max-width:479px) {
        .nobackground:hover::after {
            top: 76%;
            left: -6%;
        }
    }
    .progress-txt {
        position: absolute;
        left: 0px;
        right: 0px;
    }
    @media (max-width:649px) {
        .nobackground {
            .text-caption {
                padding-top: 0px;
            }
        }
    }
    @media (max-width:400px) {
        .progress-txt {
            font-size: 1.25rem;
            top: 20%;
        }
    }
   
    .q-field__control {
        .col {
            padding-left: 0px;
        }
    }
    .webmaster-form{
        .q-focus-helper{
            background: grey !important;
        }
    }
</style>

<template>
    <q-form ref="myForm" class="webmaster-form" novalidate @submit="submit">
        <q-expansion-item
        expand-separator
        default-opened
        label="Контактная информация">
            <q-card>
                <q-card-section>
                    <div class="wrap-percent">
                        <div class="wrapper">
                            <div class="input-wrap ">
                                <q-input :rules="phoneValidate" rounded outlined placeholder="+7 (___) ___-____" v-model="form.phone" label="Телефон" mask="+7 (###) ###-####" :color="themeColor" hint="+7 (123) 456-7890" />
                                <span style="color:red;" class="redstar">*</span>
                            </div>
                            <div class="input-wrap ">
                                <q-select :color="themeColor" :rules="emailValidate" v-model="form.email" use-input rounded outlined label="Email" :options="emailSuggestOptions" @filter="fetchEmailSuggest" new-value-mode="add" hint="example@mail.ru" behavior="menu">
                                    <template v-slot:no-option>
                                        <q-item>
                                            <q-item-section class="text-grey">
                                            Введите
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                </q-select>
                                <span style="color:red;" class="redstar">*</span>
                            </div>
                        </div>
                    </div>
                </q-card-section>
            </q-card>
        </q-expansion-item>
        <q-expansion-item
        expand-separator
        label="Личные данные"
        caption="">
            <q-card>
                <q-card-section>
                    <div class="wrapper">
                        <div class="input-wrap percent-lastname">
                            <q-select
                            :color="themeColor"
                            :rules="lastnameValidate"
                            v-model="form.lastname"
                            use-input
                            rounded
                            outlined
                            hide-selected
                            fill-input
                            input-debounce="0"
                            label="Фамилия"
                            :options="lastnameSuggestOptions"
                            @filter="fetchLastname"
                            new-value-mode="add"
                            hint="Иванов"
                            behavior="menu">
                                <template v-slot:no-option>
                                    <q-item>
                                        <q-item-section class="text-grey">
                                            Введите
                                        </q-item-section>
                                    </q-item>
                                </template>
                            </q-select>
                            <span style="color:red;" class="redstar">*</span>
                        </div>
                        <div class="input-wrap percent-name">
                            <q-select
                            :color="themeColor"
                            :rules="firstnameValidate"
                            v-model="form.firstname"
                            use-input
                            rounded
                            outlined
                            hide-selected
                            fill-input
                            input-debounce="0"
                            label="Имя"
                            :options="firstnameSuggestOptions"
                            @filter="fetchFirstname"
                            new-value-mode="add"
                            hint="Иван"
                            behavior="menu">
                                <template v-slot:no-option>
                                    <q-item>
                                        <q-item-section class="text-black">
                                            Введите
                                        </q-item-section>
                                    </q-item>
                                </template>
                            </q-select>
                            <span style="color:red;" class="redstar">*</span>
                        </div>
                    </div>

                    <div class="wrapper">
                        <div class="input-wrap percent-secondname">
                            <q-select
                            :color="themeColor"
                            :rules="secondnameValidate"
                            v-model="form.secondname"
                            use-input
                            rounded
                            outlined
                            hide-selected
                            fill-input
                            input-debounce="0"
                            label="Отчество"
                            :options="secondnameSuggestOptions"
                            @filter="fetchSecondname"
                            new-value-mode="add"
                            hint="Иванович"
                            behavior="menu">
                                <template v-slot:no-option>
                                    <q-item>
                                        <q-item-section class="text-black">
                                            Введите
                                        </q-item-section>
                                    </q-item>
                                </template>
                                </q-select>
                            <span style="color:red;" class="redstar">*</span>
                        </div>
                        <div class="input-wrap percent-dob">
                            <q-input
                            :color="themeColor"
                            :rules="dobValidate"
                            rounded
                            outlined
                            v-model="form.dob"
                            label="Дата рождения"
                            mask="##.##.####"
                            hint="25.01.1990"
                            placeholder="__.__.____"/>
                            <span style="color:red;" class="redstar">*</span>
                        </div>
                    </div>
                    <div class="wrapper">
                        <div class="input-wrap sex-flex percent-sex">
                            <div class="sex-padding" style="display:flex; flex-direction:column;">
                                <div class="right-margin" style="display:flex;">
                                    <h6>Пол</h6>
                                    <span style="color:red; right:-1%" class="redstar-custom redstar">*</span>
                                </div>
                                <div class="radios">
                                    <q-radio :rules="sexValidate" :color="themeColor" v-model="form.sex" val="М" label="М" />
                                    <q-radio :rules="sexValidate" :color="themeColor" v-model="form.sex" val="Ж" label="Ж" /> 
                                </div>
                                <div id="radio-text"></div>   
                            </div>
                        </div>
                    </div>       
                </q-card-section>
            </q-card>
        </q-expansion-item>

        <template v-if="formType === 'form-short-web'">
            <q-expansion-item expand-separator label="Адрес проживания">
                <q-card>
                    <q-card-section>
                        <div class="wrapper">
                            <div class="input-wrap percent-region">
                                <q-select
                                :color="themeColor"
                                :rules="regionValidate"
                                rounded
                                outlined
                                v-model="form.region"
                                use-input
                                hide-selected
                                fill-input
                                input-debounce="0"
                                label="Регион"
                                :options="regionSuggestOptions"
                                @filter="fetchRegionSuggest" new-value-mode="add" hint="Красноярский край"
                                behavior="menu">
                                <template v-slot:no-option>
                                    <q-item>
                                        <q-item-section class="text-grey">
                                        Введите
                                        </q-item-section>
                                    </q-item>
                                </template>
                                </q-select>
                                <span style="color:red;" class="redstar">*</span>
                            </div>
                            <div class="input-wrap percent-city">
                                <q-select
                                :color="themeColor"
                                :rules="cityValidate"
                                rounded
                                outlined
                                v-model="form.city"
                                use-input
                                hide-selected
                                fill-input
                                input-debounce="0"
                                label="Город"
                                :options="citySuggestOptions"
                                @filter="fetchCitySuggest"
                                new-value-mode="add"
                                hint="Красноярск"
                                behavior="menu">
                                    <template v-slot:no-option>
                                        <q-item>
                                            <q-item-section class="text-grey">
                                                Введите
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                </q-select>
                                <span style="color:red;" class="redstar">*</span>
                            </div>
                        </div>
                    </q-card-section>
                </q-card>
            </q-expansion-item>
        </template>

        <template v-if="formType === 'form-long-web'">
            <q-expansion-item expand-separator label="Паспортные данные">
                <q-card>
                    <q-card-section>
                        <div class="wrapper">
                            <div class="input-wrap percent-birthplace">
                                <q-input
                                :color="themeColor"
                                rounded
                                outlined
                                id="anketa-birthPlace"
                                :rules="birthPlaceValidate"
                                v-model="form.birthPlace"
                                label="Место рождения"
                                hint="гор. Красноярск (как в паспорте)"
                                placeholder="гор. Красноярск"/>
                                <span style="color:red;" class="redstar">*</span>
                            </div>
                        </div>
                        <div class="wrapper">
                            <div class="input-wrap percent-series">
                                <q-input
                                :color="themeColor"
                                rounded
                                outlined
                                id="anketa-series"
                                v-model="form.series"
                                :rules='seriesValidate'
                                label="Серия паспорта"
                                mask="####"
                                hint="1234"
                                placeholder="____" />
                                <span style="color:red;" class="redstar">*</span>
                            </div>
                            <div class="input-wrap percent-number">
                                <q-input
                                :color="themeColor"
                                rounded
                                outlined
                                id="anketa-number"
                                v-model="form.number"
                                :rules='numberValidate'
                                label="Номер паспорта"
                                mask="######"
                                hint="123456"
                                placeholder="______" />
                                <span style="color:red;" class="redstar">*</span>
                            </div>
                        </div>
                        <div class="wrapper">
                            <div class="input-wrap percent-fms">
                                <q-input
                                :color="themeColor"
                                rounded
                                outlined
                                id="anketa-fmsCode"
                                v-model="form.fmsCode"
                                :rules='fmsCodeValidate'
                                @focusout="getFmsUnit"
                                label="Код подразделения"
                                mask="###-###"
                                hint="123-456"
                                placeholder="___-___" />
                                <span style="color:red;" class="redstar">*</span>
                            </div>
                            <div class="input-wrap percent-issue">
                                <q-input
                                :rules='issueDateValidate'
                                :color="themeColor"
                                rounded
                                outlined
                                id="anketa-issueDate"
                                v-model="form.issueDate"
                                label="Дата выдачи"
                                mask="##.##.####"
                                placeholder="__.__.____"
                                hint="25.01.2010"/>
                                <span style="color:red;" class="redstar">*</span>
                            </div>
                        </div>
                    </q-card-section>
                </q-card>
            </q-expansion-item>
        </template>

        <template v-if="formType === 'form-long-web'">
            <q-expansion-item expand-separator label="Адрес проживания">
                <q-card>
                    <q-card-section>
                        <div class="wrapper">
                            <div class="input-wrap percent-region">
                                <q-select
                                :color="themeColor"
                                :rules="regionValidate"
                                rounded
                                outlined
                                v-model="form.region"
                                use-input
                                hide-selected
                                fill-input
                                input-debounce="0"
                                label="Регион"
                                :options="regionSuggestOptions"
                                @filter="fetchRegionSuggest"
                                new-value-mode="add"
                                hint="Красноярский край"
                                behavior="menu">
                                    <template v-slot:no-option>
                                        <q-item>
                                            <q-item-section class="text-grey">
                                                Введите
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                </q-select>
                                <span style="color:red;" class="redstar">*</span>
                            </div>
                            <div class="input-wrap percent-city">
                                <q-select
                                :color="themeColor"
                                :rules="cityValidate"
                                rounded
                                outlined
                                v-model="form.city"
                                use-input
                                hide-selected
                                fill-input
                                input-debounce="0"
                                label="Город"
                                :options="citySuggestOptions"
                                @filter="fetchCitySuggest"
                                new-value-mode="add"
                                hint="Красноярск"
                                behavior="menu">
                                    <template v-slot:no-option>
                                        <q-item>
                                            <q-item-section class="text-grey">
                                                Введите
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                </q-select>
                                <span style="color:red;" class="redstar">*</span>
                            </div>
                        </div>
                        <div class="wrapper">
                            <div class="input-wrap percent-street">
                                <q-input
                                :color="themeColor"
                                rounded
                                outlined
                                :rules="regStreetValidate"
                                v-model="form.regStreet"
                                label="Улица"
                                hint="Восточная"/>
                                <span style="color:red;" class="redstar">*</span>
                            </div>
                            <div class="input-wrap percent-house">
                                <q-input
                                :color="themeColor"
                                rounded
                                outlined
                                :rules="regHouseValidate"
                                v-model="form.regHouse"
                                label="Дом"
                                hint="5"/>
                                <span style="color:red;" class="redstar">*</span> 
                            </div>
                        </div>
                        <div class="wrapper no-percentage">
                            <div class="input-wrap">
                                <q-input
                                rounded
                                outlined
                                :color="themeColor"
                                v-model="form.regBlock"
                                label="Корпус (при наличии)"
                                hint="1"/>
                            </div>
                            <div class="input-wrap">
                                <q-input
                                rounded
                                outlined
                                :color="themeColor"
                                v-model="form.regApartment"
                                label="Квартира (при наличии)"
                                hint="15"/>
                            </div>
                        </div>
                    </q-card-section>
                </q-card>
            </q-expansion-item>
        </template>
            <q-expansion-item
            expand-separator
            label="Параметры займа">
                <q-card>
                    <q-card-section>
                        <div class="wrapper">
                            <div class="input-wrap percent-amount">
                                <q-input
                                id="anketa-amount"
                                :color="themeColor"
                                rounded
                                outlined
                                type="number"
                                :rules="amountValidate"
                                v-model="form.amount"
                                label="Сумма займа (рубли)"
                                hint="30000"/>
                                <span style="color:red;" class="redstar">*</span>
                            </div>
                            <div class="input-wrap percent-period">
                                <q-input
                                id="anketa-period"
                                :color="themeColor"
                                rounded
                                outlined
                                type="number"
                                :rules="periodValidate"
                                v-model="form.period"
                                label="Срок займа (дни)"
                                hint="30"/>
                                <span style="color:red;" class="redstar">*</span>
                            </div>
                        </div>
                    </q-card-section>
                </q-card>
            </q-expansion-item>
           
        <div class="nobackground" style="padding-top: 20px;padding-bottom: 20px; margin-left: -15px">
            <q-item tag="label" >
                <q-item-section  avatar top>
                    <q-checkbox
                    dense
                    keep-color
                    id="anketa-consent"
                    name='checkbox'
                    v-model="form.consent"
                    @change="validate"
                    autocomplete="off"
                    :color="themeColor"/>
                </q-item-section>

                <q-item-section>
                    <q-item-label caption v-html="getConsentText() "></q-item-label>
                </q-item-section>
            </q-item>
            <div class="row text-black" style="font-size:13px; margin-left: 15px !important;">
                <span style="color:red;">* </span> - обязательно к заполнению
            </div>
        </div>
                
        <div class="button-center button-wrapper">
            <!-- <button class="btn btn-primary btn-lg btn-block" style="width: 200px;" @click="next" :disabled="!form.consent" >Продолжить</button> -->
            <!-- <q-btn type="submit" :ripple="{ center: true}" rounded :color="themeColor" @click="next" :disabled="!form.consent" label="Продолжить" class=""></q-btn> -->
            <q-btn type="submit" :ripple="{ center: true}" rounded :color="themeColor" @click="next" :disabled="!form.consent" label="Продолжить" class=""></q-btn>
        </div>      
    </q-form>
</template>





<script>
   
    import {
        debounce
    } from 'debounce';

    import vSelect from '@/../node_modules/vue-select/src/index.js';
    import * as Suggest from '@/assets/js/suggest.js';
    import * as Validate from '@/assets/js/validators.js';
    import * as GetParams from '@/assets/js/get_params.js';
    import * as DataParams from '@/assets/js/data_params.js';
    
    export default {
        name: 'FormForWeb',
        components: {
            vSelect
        },
        props: {
            formType: String,
            forwardPage: Function,
            backwardPage: Function,
            previousPagePromise: Promise,
        },
        data: () => ({
            consentTextDefault: 'Даю свое согласие на <a href="/docs/Soglasie_na_obrabotku_personalnyh_dannyh.pdf" target="_blank">обработку персональных данных</a>, на <a href="/docs/Soglasie_na_poluchenie_reklamnyh_materialov.pdf" target="_blank">получение</a> рекламных материалов. Соглашаюсь с условиями <a href="/docs/Oferta.pdf" target="_blank">оферты</a>. Я принял соглашение на оформление <a href="/docs/Prilozhenie_4_Soglashenie_na_oformlenie_platnoj_podpiski.pdf" target="_blank">платной подписки</a>. Для активации сервиса будет списан 1 рубль, дальнейшее использование сервиса будет проходит в соответствии с <a href="/docs/Prilozhenie_2_Informacija_o_dejstvujushhih_tarifah.pdf" target="_blank">тарифами сервиса</a>. Стоимость услуги четыреста девяносто восемь рублей каждые 5 дней по подписке. Подписка не гарантирует получение займа! <span style="color:red;">* </span>',
            themeColor: DataParams.get('color'),
            isNextClicked: false,
            isPartialSubmitted: false,
            sendPromise: Promise.resolve(),
            regionSuggestOptions: [],
            citySuggestOptions: [],
            firstnameSuggestOptions: [],
            lastnameSuggestOptions: [],
            secondnameSuggestOptions: [],
            emailSuggestOptions: [],
            totalWidth: 0,
            form: {
                phone: null,
                email: null,
                lastname: null,
                firstname: null,
                secondname: null,
                dob: null,
                birthPlace: null,
                region: null,
                city: null,
                cityKladr: null,
                regionKladr: null,
                sex: null,
                amount: GetParams.get('amount'),
                period: GetParams.get('period'),
                series: null,
                number: null,
                fmsCode: null,
                fmsUnit: null,
                issueDate: null,
                consent: false,
                regHouse: null,
                regBlock: null,
                regApartment: null,
                regStreet: null,
            },
            valid: {
                phone: true,
                email: true,
                lastname: true,
                firstname: true,
                secondname: true,
                dob: true,
                birthPlace: true,
                region: true,
                city: true,
                sex: true,
                amount: true,
                period: true,
                creditHistory: true,
                series: true,
                number: true,
                fmsCode: true,
                fmsUnit: true,
                issueDate: true,
                consent: true,
                regRegion: true,
                regCity: true,
                regStreet: true,
                regHouse: true,
            },
        }),



        computed: {
            percentage: function() {
                var exclude = ['cityKladr', 'regionKladr', 'fmsUnit', 'regBlock', 'regApartment'];
                var p = 0;
                var size = 95 / (Object.keys(this.form).length - exclude.length);
                var txt = document.querySelector('.progress-txt')
                for (var i in this.form) {
                    if (exclude.includes(i)) {
                        continue;
                    }
                    if (this.form[i] != null && this.form[i] != '') {
                        p = p + size;
                    }
                }
                if (p >= 80) {
                    txt.style.color = 'white';
                }
                return Math.round(p);
            },
            phoneValidate() {
                return [
                    val => (val !== null && val !== '') || 'Введите телефон',
                    val => Validate.phone(val) || 'Введите полностью'
                ]
            },
            emailValidate() {
                return [
                    val => (val !== null && val !== '') || 'Введите email',
                    val => Validate.email(val) || 'Введите полностью'
                ]
            },
            lastnameValidate() {
                return [
                    val => (val !== null && val !== '') || 'Введите фамилию',
                    val => Validate.noempty(val) || 'Введите полностью'
                ]
            },
            firstnameValidate() {
                return [
                    val => (val !== null && val !== '') || 'Введите имя',
                    val => Validate.noempty(val) || 'Введите полностью'
                ]
            },
            secondnameValidate() {
                return [
                    val => (val !== null && val !== '') || 'Введите отчество',
                    val => Validate.noempty(val) || 'Введите полностью'
                ]
            },
            dobValidate() {
                return [
                    val => (val !== null && val !== '') || 'Введите дату рождения',
                    val => Validate.validate18Years(val) || 'Введите верную дату',
                ]
            },
            
            birthPlaceValidate() {
                return [
                    val => (val !== null && val !== '') || 'Введите город рождения',
                    val => Validate.noempty(val) || 'Введите полностью'
                ]
            },
            cityValidate() {
                return [
                    val => (val !== null && val !== '') || 'Введите город проживания',
                    val => Validate.noempty(val) || 'Введите полностью'
                ]
            },
            regionValidate() {
                return [
                    val => (val !== null && val !== '') || 'Введите регион проживания',
                    val => Validate.noempty(val) || 'Введите полностью'
                ]
            },
            seriesValidate() {
                return [
                    val => (val !== null && val !== '') || 'Введите серию паспорта',
                    val => Validate.series(val) || 'Введите полностью'
                ]
            },
            numberValidate() {
                return [
                    val => (val !== null && val !== '') || 'Введите номер паспорта',
                    val => Validate.number(val) || 'Введите полностью'
                ]
            },
            fmsCodeValidate() {
                return [
                    val => (val !== null && val !== '') || 'Введите код подразделения полностью',
                    val => Validate.fmsCode(val) || 'Введите код подразделения паспорта',
                ]
            },
            issueDateValidate() {
                return [
                    val => (val !== null && val !== '') || 'Введите дату выдачи паспорта',
                    val => Validate.date(val) || 'Введите верную дату',
                ]
            },
            regRegionValidate() {
                return [
                    val => (val !== null && val !== '') || 'Введите регион проживания',
                    val => Validate.noempty(val) || 'Введите полностью'
                ]
            },
            regCityValidate() {
                return [
                    val => (val !== null && val !== '') || 'Введите город проживания',
                    val => Validate.noempty(val) || 'Введите полностью'
                ]
            },
            regStreetValidate() {
                return [
                    val => (val !== null && val !== '') || 'Введите улицу проживания',
                    val => Validate.noempty(val) || 'Введите полностью'
                ]
            },
            regHouseValidate() {
                return [
                    val => (val !== null && val !== '') || 'Введите дом проживания',
                    val => Validate.noempty(val) || 'Введите полностью'
                ]
            },
            regApartmentValidate() {
                return [
                    val => (val !== null && val !== '') || 'Введите квартиру проживания',
                    val => Validate.noempty(val) || 'Введите полностью'
                ]
            },
            amountValidate() {
                return [
                    val => (val !== null && val !== '') || 'Введите сумму займа',
                    val => Validate.noempty(val) || 'Введите полностью'
                ]
            },
            periodValidate() {
                return [
                    val => (val !== null && val !== '') || 'Введите срок займа',
                    val => Validate.noempty(val) || 'Введите полностью'
                ]
            },
        },


        methods: {
            getConsentText() {
                return window.consent || this.consentTextDefault;
            },
            //Нажатие на кнопку "продолжить"
            next(e) {
                this.isNextClicked = true;
                if (!this.validate()) {
                    e.preventDefault();
                }
                
            },


            //Валидация формы
            validate() {
                this.valid.sex = Validate.noempty(this.form.sex)
                var div = document.getElementById('radio-text');
                if(!this.valid.sex){ 
                div.innerHTML += 'Выберите пол!';
                }
                //Не валидируем, пока не была нажата кнопка далее
                if (this.isNextClicked === false) {
                    return true;
                }
                const success = this.$refs.myForm.validate() && (this.valid.sex = Validate.noempty(this.form.sex));
                return success
            },



            //Отправка формы
            submit(e) {
                e.preventDefault();
                // Goal.newBid();
                // this.sendPromise = this.sendPromise.then(() => Bid.send(this.form));
                this.forwardPage(this.sendPromise);
            },


            //Получения названия подразделения по коду
            getFmsUnit() {
                Suggest.suggestFmsCode(this.form.fmsCode).then(data => {
                        this.form.fmsUnit = data;
                        if (!data) {
                            this.form.fmsCode = null
                        }
                    })
                    .catch(() => {
                        this.form.fmsCode = null
                    })
            },


            //Region suggestions
            fetchRegionSuggest: debounce(function(search, update) {
                if (search.length < 2) {
                    update(() => this.regionSuggestOptions = [])
                    return;
                }
                Suggest.suggestRegion(search).then(data => {
                    update(() => {
                        if (data.length)
                            this.regionSuggestOptions = data.map(el => el.text)
                        else
                            this.regionSuggestOptions = [search]
                    })
                }).catch(() => {
                    update(() => {
                        this.regionSuggestOptions = [search]
                    })
                });
            }, 500),


            //City suggestions
            fetchCitySuggest: debounce(function(search, update) {
                if (search.length < 2) {
                    update(() => this.citySuggestOptions = [])
                    return;
                }
                Suggest.suggestCity(search).then(data => {
                    update(() => {
                        if (data.length)
                            this.citySuggestOptions = data.map(el => el.text)
                        else
                            this.citySuggestOptions = [search]
                    })
                }).catch(() => {
                    update(() => {
                        this.citySuggestOptions = [search]
                    })
                });
            }, 500),


            //Fio suggestions
            fetchFirstname: debounce(function(search, update) {
                if (search.length < 2) {
                    update(() => this.firstnameSuggestOptions = [search])
                    return;
                }
                this.fetchFioSuggest(search, update, 'name')
            }, 500),
            fetchLastname: debounce(function(search, update) {
                if (search.length < 2) {
                    update(() => this.lastnameSuggestOptions = [search])
                    return;
                }
                this.fetchFioSuggest(search, update, 'surname')
            }, 500),
            fetchSecondname: debounce(function(search, update) {
                if (search.length < 2) {
                    update(() => this.secondnameSuggestOptions = [search])
                    return;
                }
                this.fetchFioSuggest(search, update, 'patronymic')
            }, 500),
            fetchFioSuggest(search, update, type) {
                Suggest.suggestFio(search, type).then(data => {
                    update(() => {
                        if (type === 'name') {
                            this.firstnameSuggestOptions = data.map(el => el.text);
                        }
                        if (type === 'surname') {
                            this.lastnameSuggestOptions = data.map(el => el.text);
                        }
                        if (type === 'patronymic') {
                            this.secondnameSuggestOptions = data.map(el => el.text);
                        }
                    })
                }).catch(() => {
                    update(() => {
                        if (type === 'name') {
                            this.firstnameSuggestOptions = [search]
                        }
                        if (type === 'surname') {
                            this.lastnameSuggestOptions = [search]
                        }
                        if (type === 'patronymic') {
                            this.secondnameSuggestOptions = [search]
                        }
                    })
                });
            },


            //Email suggestions
            fetchEmailSuggest: debounce(function(search, update) {
                if (search.length < 2) {
                    update(() => this.emailSuggestOptions = [])
                    return;
                }
                Suggest.suggestEmail(search).then(data => {
                    update(() => {
                        if (data.length)
                            this.emailSuggestOptions = data.map(el => el.text)
                        else
                            this.emailSuggestOptions = [search]
                    })
                }).catch(() => {
                    update(() => {
                        this.emailSuggestOptions = [search]
                    })
                });
            }, 500),
        },

        
        mounted() {
            //Подстановка geo по ip
            Suggest.getGeoFromIp().then(geo => {
                if (geo.cityKladr === null) {
                    return;
                }
                this.form.region = geo.region;
                this.form.regionKladr = geo.regionKladr;
                this.form.city = geo.city;
                this.form.cityKladr = geo.cityKladr;
                this.regionSuggestOptions[this.form.region] = this.form.regionKladr;
                this.citySuggestOptions[this.form.city] = this.form.cityKladr;
            });
        }
    }
</script>
    
    