<template>
    <div class="payment-form-header">
        Уважаемый {{ firstname }} {{ secondname }}! Мы проверили ваши данные.
    </div>

    <iframe height="830" width="100%" scrolling="no" :src="formUrl" style="border:none;"></iframe>

    <div class="payment-form-footer" style="display:none;">
        <div class="payment-form-small">
            
        </div>
    </div>
</template>

<script>
import * as Subscription from '@/assets/js/bid/subscription_iframe.js'

export default {
    name: 'SubscriptionPlatron',

    props: {
        renderForm: Function,
        renderError: Function
    },

    data: () => ({
        formUrl: null,
        firstname: '',
        secondname: ''
    }),

    mounted () {
        Subscription.init().then(data => {
            if (data.error) {
                this.renderError(data.error);
            } else {
                this.firstname = window.bid.firstname;
                this.secondname = window.bid.secondname;
                this.formUrl = data.redirect;
                this.renderForm();
            }
        });
    }
}
</script>
